import Axios from "../../axios";

export const get = async (endPoint, param) => {
  try {
    const result = await Axios.get(endPoint, { params: param });
    if (result.status === 200) return result.data;
    else throw result;
  } catch (e) {
    throw e;
  }
};

export const post = async (endPoint, data) => {
  try {
    const result = await Axios.post(endPoint, data);
    if (result.status === 200) return result.data;
    else throw result;
  } catch (e) {
    throw e;
  }
};
