import ServicesRoutes from "./Services.routes";
import { get, post } from "../index";

const ServicesService = {
  getService: async () => {
    let result = await get(ServicesRoutes.getServices);
    return result;
  },

  submitService: async (obj) => {
    let result = await post(ServicesRoutes.submitService, obj);
    return result;
  },
};

export default ServicesService;
