import OrderDetails from "../View/Orders/OrderDetails";
import Orders from "../View/Orders/Orders";

const AdminRoute = [
  {
    path: "/orders",
    component: <Orders />,
  },
  { path: "/orderdetails/:id", component: <OrderDetails /> },
];

export default AdminRoute;
