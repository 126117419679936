import React, { Fragment, useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Container,
  CircularProgress,
  IconButton,
  CardMedia,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../Assets/Styles/Colors";
import { Images } from "../../Assets/Images/Images";
import AuthService from "../../Api/Auth/AuthService";
import { useAuth } from "../../Context/UseContext";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { userLogin } = useAuth();
  const navigate = useNavigate();

  const login = async (formData) => {
    setLoading(true);
    try {
      let obj = {
        email: formData.email,
        password: formData.password,
      };
      const { data, responseCode } = await AuthService.login(obj);
      userLogin(data);
      if (responseCode === 200) {
        navigate("/orders");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            pt: 12,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CardMedia
            component={"img"}
            width={"120px"}
            height={"90px"}
            src={Images.logo}
            sx={{ objectFit: "contain" }}
          />
          <Box component="form" onSubmit={handleSubmit(login)} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              label={"Email"}
              type="text"
              {...register("email", {
                required: "Please enter your email",
              })}
              error={errors?.email?.message}
              helperText={errors?.email?.message}
            />
            <TextField
              margin="normal"
              fullWidth
              label={"Password"}
              type={showPassword ? "text" : "password"}
              {...register("password", {
                required: "Please enter your password",
              })}
              error={errors?.password?.message}
              helperText={errors?.password?.message}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                ":hover": {
                  color: Colors.white,
                  backgroundColor: Colors.secondary,
                },
                backgroundColor: Colors.primary,
                color: Colors.white,
              }}
            >
              {loading ? <CircularProgress size={22} /> : "Login"}
            </Button>
          </Box>
        </Box>
      </Container>
    </Fragment>
  );
}
