export const Colors = {
  primary: "#6CBE1E",
  secondary: "#1FC6C7",
  white: "#ffffff",
  smokeWhite: "#FBFBFB",
  black: "#000000",
  blueShade: "#277d98",
  grey: "#E6E8EE",
  greyText: "#9b9b9b",
  danger: "#D32F2F",
  smokeGreen: "#fbfbfb",
  danger: "#FF0000",
};
