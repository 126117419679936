import React, { Fragment, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  Container,
  Paper,
  Divider,
  ButtonGroup,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  useTheme,
  TextField,
  CardMedia,
  FormGroup,
  FormHelperText,
  SwipeableDrawer,
  IconButton,
  InputAdornment,
  Autocomplete,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import {
  Person,
  LocalPhone,
  Email,
  LocalLibrary,
  Payments,
  CreditCard,
  CheckCircleOutline,
  Check,
  Villa,
  Streetview,
  Close,
  KeyboardArrowUp,
  KeyboardArrowLeft,
  ChevronRight,
  ExpandMore,
  TroubleshootOutlined,
} from "@mui/icons-material";
import InputField from "../../Components/InputField/InputField";
import ServiceCard from "../../Components/Card/Card";
import { Images } from "../../Assets/Images/Images";
import { Colors } from "../../Assets/Styles/Colors";
import { Calendar } from "react-multi-date-picker";
import { styled } from "@mui/system";
import { useForm, useWatch } from "react-hook-form";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import ServicesService from "../../Api/Services/ServicesService";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import "../../../App.css";
import HomeIcon from "@mui/icons-material/Home";
import useMediaQuery from "@mui/material/useMediaQuery";
import Modal from "@mui/material/Modal";
import AddressForm from "../../Components/AddressFormDialog";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%", // Default width

  "@media (max-width: 768px)": {
    width: "80%", // Adjust width for screens smaller than 768px
  },

  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  height: "500px",
  overflowY: "scroll",
  zIndex: "1111111",
  p: 4,

  // Scrollbar styling
  "&::-webkit-scrollbar": {
    width: "12px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#1FC6C7",
    borderRadius: "6px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f1f1f1",
  },
  "scrollbar-width": "12px",
  "scrollbar-color": "#1FC6C7 #f1f1f1",
};

function BookingSummaryDrawer(props) {
  const {
    open,
    onClose,
    selectedService,
    selectedPackage,
    arrayOfObjects,
    address,
    setPrice1,
    setMaterialPrice1,
    setTaxable1,
    setVat1,
    setGrandTotal1 } = props;

  const [price, setPrice] = useState(selectedService?.price);
  const [materialPrice, setMaterialPrice] = useState(0);
  const [taxable, setTaxable] = useState(0);
  const [vat, setVat] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  useEffect(() => {
    let totalPrice = 0;
    let materialPrice = 25;
    let totalHours = 0;
    let totalMaids = 0;
    let totalMaterialPrice = 0;
    let totalCleaningRequired = 0;

    for (let i = 0; i < arrayOfObjects.length; i++) {
      const element = arrayOfObjects[i];
      totalHours += parseInt(element.hours);
      totalMaids += parseInt(element.maids);
      if (element.cleaning_material == true) {
        totalCleaningRequired++;
      }
      if (parseInt(element.hours) > 0) {
        totalPrice += selectedService?.price * parseInt(element.hours) * parseInt(element.maids)
      }
      else {
        totalPrice += selectedPackage?.price ? selectedPackage?.price : 0
      }
      if (element.cleaning_material == true) {
        totalMaterialPrice += materialPrice * parseInt(element.maids)
      }
    }

    let taxable = totalPrice + totalMaterialPrice;
    let vat = taxable * 0.05;
    let grandTotal = taxable + vat;

    setPrice(totalPrice);
    setPrice1(totalPrice)
    setMaterialPrice(totalMaterialPrice);
    setMaterialPrice1(totalMaterialPrice);
    setTaxable(taxable);
    setTaxable1(taxable);
    setVat(vat);
    setVat1(vat);
    setGrandTotal(grandTotal);
    setGrandTotal1(grandTotal);

  }, [arrayOfObjects]);

  return (
    <SwipeableDrawer
      sx={{ width: "100%", overflowY: "scroll !important" }}
      role="presentation"
      onClose={onClose}
      open={open}
      anchor={"bottom"}
    >
      <Box
        component={Paper}
        sx={{
          display:
            selectedService?.name === "Office Cleaning" ? "none" : "block",
          height: "100% !important",
        }}
      >
        <Box
          sx={{
            py: "10px",
            px: "30px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontWeight: 700, fontSize: "26px" }}>
            Booking Summary
          </Typography>
          <IconButton sx={{ p: 0 }} onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Box sx={{ p: "10px 30px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: "5px 0",
            }}
          >
            <Typography variant="subtitle2">Service Type </Typography>
            <Typography variant="subtitle2">{selectedService?.name}</Typography>
          </Box>
        </Box>
        {/* Accordion */}
        <Box>
          <Accordion disableGutters square={true}>
            <AccordionSummary
              sx={{
                backgroundColor: Colors.secondary,
                color: Colors.white,
                p: "0px 30px",
                "& .MuiAccordionSummary-expandIconWrapper": {
                  backgroundColor: Colors.white,
                  borderRadius: "50%",
                },
              }}
              expandIcon={<ChevronRight sx={{ color: Colors.secondary }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle2">Details</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                p: 0,
                minHeight: selectedService?.pricing_type == "hour" ? "160px" : "130px",
                maxHeight: "300px",
                overflow: "scroll",
              }}
            >
              {arrayOfObjects?.map((obj, i) => (
                <Fragment>
                  <Box key={i} sx={{ p: "16px 30px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: "5px 0",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ color: Colors.secondary, fontWeight: 600 }}
                      >
                        {obj.date.toLocaleDateString()}
                      </Typography>
                      <Typography variant="subtitle2">Arrival Time</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: "5px 0",
                      }}
                    >
                      {obj.hours == 8 ? (
                        <Typography variant="subtitle2">
                          Morning(8am-10am)
                        </Typography>
                      ) : (
                        <Fragment>
                          <Typography variant="subtitle2">
                            {obj.slot}
                          </Typography>
                          <Typography variant="subtitle2">
                            {obj.arrivalTime}
                          </Typography>
                        </Fragment>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display:
                          selectedService?.pricing_type == "hour"
                            ? "flex"
                            : "none",
                        justifyContent: "space-between",
                        p: "5px 0",
                      }}
                    >
                      <Typography variant="subtitle2">
                        Number of Maids
                      </Typography>
                      <Typography variant="subtitle2">
                        {isNaN(obj.maids) ? "-" : obj.maids}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display:
                          selectedService?.pricing_type == "hour"
                            ? "flex"
                            : "none",
                        justifyContent: "space-between",
                        p: "5px 0",
                      }}
                    >
                      <Typography variant="subtitle2">
                        Number of Hours
                      </Typography>
                      <Typography variant="subtitle2">
                        {isNaN(obj.hours) ? "-" : obj.hours}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display:
                          selectedService?.pricing_type == "hour"
                            ? "flex"
                            : "none",
                        justifyContent: "space-between",
                        p: "5px 0",
                      }}
                    >
                      <Typography variant="subtitle2">
                        Cleaning Material
                      </Typography>
                      <Typography variant="subtitle2">
                        {obj.cleaning_material == true ? "Yes" : "No"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display:
                          selectedService?.name == "Deep Cleaning"
                            || selectedService?.name == "AirBnB/Rental Home Cleaning"
                            ? "flex"
                            : "none",
                        justifyContent: "space-between",
                        p: "5px 0",
                      }}
                    >
                      <Typography variant="subtitle2">
                        Number of bedrooms
                      </Typography>
                      <Typography variant="subtitle2">{selectedPackage?.space}</Typography>
                    </Box>
                  </Box>
                  <Divider />
                </Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            square={true}
            sx={{
              "&.MuiAccordion-root:before": {
                top: "-2px",
                height: "2px",
                backgroundColor: Colors.white,
                opacity: 1,
              },
            }}
          >
            <AccordionSummary
              sx={{
                backgroundColor: Colors.secondary,
                color: Colors.white,
                p: "0px 30px",
                "& .MuiAccordionSummary-expandIconWrapper": {
                  backgroundColor: Colors.white,
                  borderRadius: "50%",
                },
              }}
              expandIcon={<ChevronRight sx={{ color: Colors.secondary }} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="subtitle2">Address</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: "16px 30px" }}>
              <Typography variant="subtitle2">{address}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            square={true}
            expanded
            sx={{
              "&.MuiAccordion-root.Mui-expanded:before": {
                top: "-2px",
                height: "2px",
                backgroundColor: Colors.white,
                opacity: 1,
              },
            }}
          >
            <AccordionSummary
              sx={{
                backgroundColor: Colors.secondary,
                color: Colors.white,
                p: "0px 30px",
                "& .MuiAccordionSummary-expandIconWrapper": {
                  backgroundColor: Colors.white,
                  borderRadius: "50%",
                },
              }}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography variant="subtitle2">Price Details</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: "16px 30px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: "5px 0",
                }}
              >
                <Typography variant="subtitle2">Price </Typography>
                <Typography variant="subtitle2">AED {price?.toFixed(2)}</Typography>
              </Box>
              <Box
                sx={{
                  display:
                    selectedService?.name === "Home Cleaning" ||
                      selectedService?.name === "Folding & Ironing Service"
                      ? "flex"
                      : "none",
                  justifyContent: "space-between",
                  p: "5px 0",
                }}
              >
                <Typography variant="subtitle2">Material Price </Typography>
                <Typography variant="subtitle2">
                  AED {materialPrice?.toFixed(2)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: "5px 0",
                }}
              >
                <Typography variant="subtitle2">Taxable </Typography>
                <Typography variant="subtitle2">
                  AED {taxable?.toFixed(2)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: "5px 0",
                }}
              >
                <Typography variant="subtitle2">VAT 5% </Typography>
                <Typography variant="subtitle2">
                  AED {vat?.toFixed(2)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: "5px 0",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: "20px",
                    color: Colors.secondary,
                  }}
                >
                  Total{" "}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: "20px",
                    color: Colors.secondary,
                  }}
                >
                  {`AED ${grandTotal?.toFixed(2)}`}
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        {/* Accordion */}
      </Box>
    </SwipeableDrawer>
  );
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: Colors.secondary,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: Colors.primary,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : Colors.primary,
  display: "flex",
  height: 22,
  alignItems: "center",
  "& .QontoStepIcon-completedIcon": {
    color: Colors.primary,
    zIndex: 1,
    fontSize: 16,
  },
  "& .QontoStepIcon-circle": {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: ownerState.active === true ? Colors.primary : "#c4c4c4",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot sx={{}} ownerState={{ active }} className={className}>
      {completed ? (
        <Box
          sx={{
            backgroundColor: Colors.white,
            height: "20px",
            width: "20px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Check className="QontoStepIcon-completedIcon" />
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: Colors.white,
            height: "20px",
            width: "20px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="QontoStepIcon-circle" />
        </Box>
      )}
    </QontoStepIconRoot>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Toaster = () => {
  return (
    <ToastContainer position="top-center" autoClose="2000" hideProgressBar={true} />
  )
}

function Booking() {
  const theme = useTheme();
  const [addressFormDialog, setAddressFormDialog] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const steps = [
    "Choose Service",
    "Choose Frequency",
    "Customer Details",
    "Payment Details",
  ];

  const timeSlots = [
    {
      id: 0,
      slot: "Morning",
      arrivalTime: "(8am-10am)",
    },
    {
      id: 1,
      slot: "Afternoon",
      arrivalTime: "(12pm-2pm)",
    },
  ];

  const AirBNBtimeSlots = [
    {
      id: 0,
      slot: "8am",
      arrivalTime: "8am",
    },
    {
      id: 1,
      slot: "9am",
      arrivalTime: "9am",
    },
    {
      id: 2,
      slot: "10am",
      arrivalTime: "10am",
    },
    {
      id: 3,
      slot: "11am",
      arrivalTime: "11am",
    },
    {
      id: 4,
      slot: "12pm",
      arrivalTime: "12pm",
    },
  ];

  const deepCleaningPackages = [
    {
      id: 0,
      type: "Apartment",
      packages: [
        {
          value: 1,
          space: "Studio",
          price: 350,
          maids: 2,
          hours: 4,
        },
        {
          value: 2,
          space: "1 bedroom",
          price: 520,
          maids: 3,
          hours: 4,
        },
        {
          value: 3,
          space: "2 bedrooms",
          price: 740,
          maids: 4,
          hours: 4,
        },
        {
          value: 4,
          space: "3 bedrooms",
          price: 930,
          maids: 4,
          hours: 6,
        },
        {
          value: 5,
          space: "4 bedrooms",
          price: 1210,
          maids: 6,
          hours: 6,
        },
      ]
    },
    {
      id: 1,
      type: "Villa",
      packages: [
        {
          value: 1,
          space: "3 bedrooms",
          price: 1250,
          maids: 6,
          hours: 6,
        },
        {
          value: 2,
          space: "4 bedrooms",
          price: 1460,
          maids: 6,
          hours: 6,
        },
        {
          value: 3,
          space: "5 bedrooms",
          price: 1850,
          maids: 6,
          hours: 8,
        },
        {
          value: 4,
          space: "6 bedrooms",
          price: 2430,
          maids: 6,
          hours: 8,
        },
        {
          value: 5,
          space: "7 bedrooms",
          price: 2950,
          maids: 6,
          hours: 8,
        },
      ]
    },
  ]

  const airbnbCleaningPackages = [
    {
      value: 1,
      space: "Studio",
      price: 200,
    },
    {
      value: 2,
      space: "1 bedroom",
      price: 260,
    },
    {
      value: 3,
      space: "2 bedrooms",
      price: 390,
    },
    {
      value: 4,
      space: "3 bedrooms",
      price: 520,
    },
    {
      value: 5,
      space: "4 bedrooms",
      price: 650,
    },
    {
      value: 6,
      space: "5 bedrooms",
      price: 780,
    },
    {
      value: 7,
      space: "6 bedrooms",
      price: 910,
    },
  ];

  const frequencies = ["Once", "Schedule"];
  const homeTypes = ["Apartment", "Villa"];
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [numberOfHours, setNumberOfHours] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedFrequency, setSelectedFrequency] = useState(0);
  const [selectedHomeType, setSelectedHomeType] = useState("");
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [indicatorPosition, setIndicatorPosition] = useState(63.7987);
  const [indicatorPosition1, setIndicatorPosition1] = useState(104.116);
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [price1, setPrice1] = useState(selectedService?.price);
  const [materialPrice1, setMaterialPrice1] = useState(0);
  const [defaultMaterial, setDefaultMaterial] = useState("Yes");
  const [taxable1, setTaxable1] = useState(0);
  const [vat1, setVat1] = useState(0);
  const [grandTotal1, setGrandTotal1] = useState(0);
  const [selectedDate, setSelectedDate] = useState([]);
  const [response, setResponse] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [state, setState] = useState(false);
  const [address, setAddress] = useState("");
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [SubmitDisabled, setSubmitDisabled] = useState(true);
  const [addressData, setAddressData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const formValues = useWatch({ control });

  let arrayOfObjects = [];
  for (let i = 0; i < selectedDate.length; i++) {
    const uniqueId = Math.floor(Math.random() * 10000000)
    let obj = {
      id: uniqueId,
      date: selectedDate[i],
      slot: formValues["timeSlot" + moment(selectedDate[i]).format("YYYY-MM-DD")],
      hours: parseFloat(formValues["numberOfHours" + moment(selectedDate[i]).format("YYYY-MM-DD")]),
      maids: parseFloat(formValues["numberOfMaids" + moment(selectedDate[i]).format("YYYY-MM-DD")]),
      cleaning_material: formValues["material" + moment(selectedDate[i]).format("YYYY-MM-DD")] == "Yes" ? true : false,
      arrivalTime: timeSlots.find(
        (time) => time.slot == formValues["timeSlot" + moment(selectedDate[i]).format("YYYY-MM-DD")]
      )?.arrivalTime,
    };
    arrayOfObjects.push(obj);
  }

  const submitForm = async (formData) => {
    if (selectedService.pricing_type == "Contact us") {
      setActiveStep(2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 3 && selectedService.pricing_type !== "Contact us") {
      const obj = {
        service_id: selectedService?._id,
        price: price1,
        material_price: materialPrice1,
        taxable: taxable1,
        vat: vat1,
        grand_total: grandTotal1,
        summary: {
          name: selectedService?.name,
          price: selectedService?.price,
          pricing_type: selectedService?.pricing_type,
          price_per_maid: selectedService?.price_per_maid,
          material_price: selectedService?.pricing_type == "hour" ? 25 : 0,
          notes: formData?.notes,
          type: selectedFrequency == 0 ? "once" : "multi",
          house_type: selectedHomeType == 0 ? "Apartment" : "Villa",
          package: {
            price: selectedPackage?.price,
            option: selectedPackage?.space,
          }
        },
        frequency: arrayOfObjects.map((obj) => ({
          date: obj.date,
          slot: selectedService?.name == "Home Cleaning" || selectedService?.name == "Folding & Ironing Service" || selectedService?.name == "Deep Cleaning" ? obj.slot + obj.arrivalTime : obj.slot,
          maids: obj.maids,
          hours: obj.hours,
          sqft: obj.sqft,
          cleaning_material: obj.cleaning_material
        })),
        contact: {
          name: formData?.name,
          number: formData?.contact,
          email: formData?.email,
          address: addressData?.address,
          building: addressData?.buildingStreet,
          flat: addressData?.flatVilla,
          latitude: addressData?.center?.latitude,
          longitude: addressData?.center?.longitude,
          postal_code: "",
          city: addressData?.city,
        },
        payment: {
          sub_total: taxable1,
          material: materialPrice1,
          total: grandTotal1,
          method: paymentMethod,
          status: "pending",
        },
      };
      let { responseCode, message } = await ServicesService.submitService(obj);
      if (responseCode === 200) {
        setResponse(message);
      }
    } else {
      if (activeStep === 2 && selectedService?.pricing_type === "Contact us") {
        const obj = {
          service_id: selectedService?._id,
          summary: {
            name: selectedService?.name,
            price: selectedService?.price,
            pricing_type: selectedService?.pricing_type,
            price_per_maid: selectedService?.price_per_maid,
            material_price: materialPrice1,
            notes: "",
            type: "",
            house_type: "",
            package: {}
          },
          frequency: [],
          contact: {
            name: formData?.name,
            number: formData?.contact,
            email: formData?.email,
            address: addressData?.address,
            building: addressData?.buildingStreet,
            flat: addressData?.flatVilla,
            latitude: addressData?.center?.latitude,
            longitude: addressData?.center?.longitude,
            postal_code: "",
            city: addressData?.city,
          },
          payment: {
            sub_total: 0,
            material: 0,
            total: 0,
            method: "",
            status: "pending",
          },
        };
        let { responseCode, message } = await ServicesService.submitService(
          obj
        );
        if (responseCode === 200) {
          setResponse(message);
          setActiveStep(4);
        }
      }
    }
  };

  const getHoursRange = (service) => {
    const { min_hours } = service;
    const hoursRange = [];

    for (let i = min_hours; i <= 8; i++) {
      hoursRange.push(i);
    }
    setNumberOfHours(hoursRange);
  };

  const getServices = async (serviceId) => {
    try {
      let { data } = await ServicesService.getService();
      setServices(data?.services);

      let selectedServ = data?.services.find((x) => x._id == serviceId);

      if (selectedServ) {
        handleCardClick(selectedServ);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const messageHandler = (message) => toast.error(message)

  const handleCardClick = (selectedItem) => {
    if (selectedService === null) {
      setSelectedService(selectedItem);
      getHoursRange(selectedItem);
      setSelectedDate([]);
      setSelectedPackage({});
      reset();
    } else if (selectedService?.name !== selectedItem.name) {
      setSelectedService(selectedItem);
      getHoursRange(selectedItem);
      setSelectedDate([]);
      setSelectedPackage({});
      reset();
    }
  };

  const handleBack = (e) => {
    if (selectedService?.pricing_type === "Contact us") {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedFrequency(newValue);
    setSelectedDate([]);
    setSelectedPackage({});
    reset();
    const tabWidth = event.currentTarget.clientWidth;
    const tabPosition = event.currentTarget.offsetLeft;
    const newPosition = tabPosition + tabWidth / 2.2571;
    setIndicatorPosition(newPosition);
    setIndicatorPosition1(newPosition)
  };

  const handleHomeTypeChange = (event, newValue) => {
    setSelectedPackage({});
    setSelectedHomeType(newValue);
    const selectedTypePackages = deepCleaningPackages?.find((item) => item.id == newValue)?.packages;
    setPackages(selectedTypePackages);
  };

  const handlePackageChange = (event) => {
    if (selectedService?.name == "Deep Cleaning") {
      const selected = packages?.find((item) => item.value == event?.target?.value);
      setSelectedPackage(selected);
    } else if (selectedService?.name == "AirBnB/Rental Home Cleaning") {
      const selected = airbnbCleaningPackages.find((item) => item.value == event?.target?.value);
      setSelectedPackage(selected);
    }
  }

  const handleDateChange = (dates) => {
    const currentDate = new Date();
    let formattedDates = [];
    if (selectedFrequency === 0) {
      if (selectedService?.pricing_type == "hour") {
        // Single date selection
        const selectedDate = new Date(dates);
        if (moment(selectedDate).isSame(currentDate, 'day')) {
          setOpenSnackBar(true);
        } else {
          setSelectedDate([selectedDate]);
          const defaultSelected = getValues(`material${moment(selectedDate).format("YYYY-MM-DD")}`);
          if (!defaultSelected) {
            setValue(`material${moment(selectedDate).format("YYYY-MM-DD")}`, defaultMaterial);
            setValue(`numberOfMaids${moment(selectedDate).format("YYYY-MM-DD")}`, 1);
            setValue(`numberOfHours${moment(selectedDate).format("YYYY-MM-DD")}`, 4);
            setValue(`timeSlot${moment(selectedDate).format("YYYY-MM-DD")}`, "Morning");
          }
        }
      } else if (selectedService?.pricing_type == "starting_from") {
        if (selectedService?.name == "AirBnB/Rental Home Cleaning") {
          const selectedDate = new Date(dates);
          if (moment(selectedDate).isSame(currentDate, 'day')) {
            setOpenSnackBar(true);
          } else {
            setSelectedDate([selectedDate]);
            setValue(`timeSlot${moment(selectedDate).format("YYYY-MM-DD")}`, "8am");
          }
        } else if (selectedService?.name == "Deep Cleaning") {
          const selectedDate = new Date(dates);
          if (moment(selectedDate).isSame(currentDate, 'day')) {
            setOpenSnackBar(true);
          } else {
            setSelectedDate([selectedDate]);
            setValue(`timeSlot${moment(selectedDate).format("YYYY-MM-DD")}`, "Morning");
          }
        }
      }
    } else if (selectedFrequency === 1) {
      if (selectedService?.pricing_type == "hour") {
        // Multiple dates selection
        formattedDates = dates.map((date, i) => {
          const selectedDate = new Date(date);
          if (moment(selectedDate).isSame(currentDate, 'day')) {
            setOpenSnackBar(true);
            return null; // Invalid date, set to null
          } else {
            const defaultSelected = getValues(`material${moment(selectedDate).format("YYYY-MM-DD")}`);
            if (!defaultSelected) {
              setValue(`material${moment(selectedDate).format("YYYY-MM-DD")}`, defaultMaterial);
              setValue(`numberOfMaids${moment(selectedDate).format("YYYY-MM-DD")}`, 1);
              setValue(`numberOfHours${moment(selectedDate).format("YYYY-MM-DD")}`, 4);
              setValue(`timeSlot${moment(selectedDate).format("YYYY-MM-DD")}`, "Morning");
            }
            return selectedDate;
          }
        }).filter(date => date !== null);

        formattedDates.sort((a, b) => a - b);

        setSelectedDate(formattedDates);
      } else if (selectedService?.pricing_type == "starting_from") {
        // Multiple dates selection for AirBnB/Rental Home Cleaning 
        if (selectedService?.name == "AirBnB/Rental Home Cleaning") {
          formattedDates = dates.map((date, i) => {
            const selectedDate = new Date(date);
            if (moment(selectedDate).isSame(currentDate, 'day')) {
              setOpenSnackBar(true);
              return null; // Invalid date, set to null
            } else {
              const defaultSelected = getValues(`timeSlot${moment(selectedDate).format("YYYY-MM-DD")}`);
              if (!defaultSelected) {
                setValue(`timeSlot${moment(selectedDate).format("YYYY-MM-DD")}`, "8am")
              }
              return selectedDate;
            }
          }).filter(date => date !== null);

          formattedDates.sort((a, b) => a - b);

          setSelectedDate(formattedDates);
        } else if (selectedService?.name == "Deep Cleaning") {
          formattedDates = dates.map((date, i) => {
            const selectedDate = new Date(date);
            if (moment(selectedDate).isSame(currentDate, 'day')) {
              setOpenSnackBar(true);
              return null; // Invalid date, set to null
            } else {
              const defaultSelected = getValues(`timeSlot${moment(selectedDate).format("YYYY-MM-DD")}`);
              if (!defaultSelected) {
                setValue(`timeSlot${moment(selectedDate).format("YYYY-MM-DD")}`, "Morning");
              }
              return selectedDate;
            }
          }).filter(date => date !== null);

          formattedDates.sort((a, b) => a - b);

          setSelectedDate(formattedDates);
        }
      }
    }
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const handleDateRemove = (dateToRemove) => {
    const updatedDates = selectedDate.filter(
      (date) => !isSameDay(date, dateToRemove)
    );
    updatedDates.sort((a, b) => a - b);
    setSelectedDate(updatedDates);
    if (selectedDate.length <= 1) {
      reset();
    }
  };

  const handleIncrement = (id) => {
    let value = getValues(`numberOfMaids${moment(selectedDate[id]).format("YYYY-MM-DD")}`);
    setValue(`numberOfMaids${moment(selectedDate[id]).format("YYYY-MM-DD")}`, parseFloat(value) + 1);
  };

  const handleDecrement = (id) => {
    let value = getValues(`numberOfMaids${moment(selectedDate[id]).format("YYYY-MM-DD")}`);
    if (value > 1) {
      setValue(`numberOfMaids${moment(selectedDate[id]).format("YYYY-MM-DD")}`, parseFloat(value) - 1);
    } else {
      setValue(`numberOfMaids${moment(selectedDate[id]).format("YYYY-MM-DD")}`, parseFloat(1));
    }
  };

  const handleTimeSlotChange = (e, id) => {
    let slot;
    let value = e.target.value;
    if (selectedService.name !== "AirBnB/Rental Home Cleaning") {
      slot = timeSlots.find((slot) => slot.id == value)?.slot;
      setValue(`timeSlot${moment(selectedDate[id]).format("YYYY-MM-DD")}`, slot);
    }
    if (selectedService.name == "AirBnB/Rental Home Cleaning") {
      slot = AirBNBtimeSlots.find((slot) => slot.id == value)?.slot;
      setValue(`timeSlot${moment(selectedDate[id]).format("YYYY-MM-DD")}`, slot);
    }
  };

  const handleNumOfHoursChange = (e, id) => {
    setValue(`numberOfHours${moment(selectedDate[id]).format("YYYY-MM-DD")}`, parseFloat(e.target.value));
  };

  const handleMaterialChange = (e, id) => {
    setValue(`material${moment(selectedDate[id]).format("YYYY-MM-DD")}`, e.target.value);
  };

  const handleSnackbarClose = () => {
    setOpenSnackBar(false);
  }

  useEffect(() => {
    handlePackageChange();
  }, [selectedHomeType]);

  useEffect(() => {
    let serviceId = searchParams.get("id");
    getServices(serviceId)
  }, []);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleCheckboxChange = (e) => {
    setOpen(true);
  };
  const handleTerms = () => {
    setSubmitDisabled(false);
    setIsChecked(true);
    setOpen(false);
  };

  const saveAddress = async (data) => {
    try {
      setAddressFormDialog(false);
      setAddressData(data);
      setAddress(data?.address);
      setValue("address1", data?.address);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: Colors.smokeGreen,
        height: {
          md: activeStep === 0 || activeStep === 4 ? "100vh" : "100%",
          sm: "100vh",
          xs: "100vh",
        },
      }}
    >
      <Toaster />
      {/* // Terms Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: "none  !important" }}
        maxWidth="md"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Terms & Conditions
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <ol>
              <li>
                In case the scope of the job is found to be more extensive after
                the inspection upon arrival, we might need extra equipment /
                time and the pricing might differ.
              </li>
              <li>
                Client may cancel or adjust without any charges: the time of a
                cleaning visit/s by giving at least 24 hours advanced notice.
              </li>
              <li>
                Kindly take note that a cancellation fee amounting to 50% shall
                be levied should you opt to cancel your booking within 12 hours
                of the pre-scheduled appointment. In the event of an on the spot
                cancellation, an entire 100% cancellation fee shall be applied.
              </li>
              <li>
                You agree to pay the full price of the cleaning visit in the
                event of an on the spot cancellation, lock-out, no one home to
                let them in or a problem with your keys.
              </li>
              <li>
                Proceeding with your booking confirms your acceptance to Coco
                Prime terms and conditions.
              </li>
            </ol>
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleTerms}>Accept</Button>
          </Box>
        </Box>
      </Modal>

      <AddressForm
        open={addressFormDialog}
        onClose={() => setAddressFormDialog(false)}
        save={(data) => saveAddress(data)}
      />

      {/* Header */}
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          zIndex: 999,
          pt: "20px",
          pb: "10px",
          backgroundColor: Colors.smokeWhite,
        }}
      >
        {/* Home Icon, Back Button Logo */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: { sm: "15px", xs: "15px" },
            alignItems: "center",
            // boxShadow: "0px 0px 5px 1px #c4c4c4",
          }}
        >
          <IconButton
            disabled={activeStep === 0 && true}
            sx={{ display: { md: "none", sm: "flex", xs: "flex" } }}
            onClick={handleBack}
          >
            <KeyboardArrowLeft sx={{ color: Colors.primary }} />
          </IconButton>
          <Box>
            <Box
              component={"a"}
              href="https://cocoprimecleaning.ae/"
              sx={{
                display: "flex",
                width: { md: "199px", sm: "139px", xs: "60px" },
                height: { md: "102px", sm: "72px", xs: "40px" },
              }}
            >
              <CardMedia
                component={"img"}
                src={Images.logo}
                sx={{
                  objectFit: "contain",
                  ml: { md: "20px" },
                }}
              />
            </Box>
          </Box>
          <Typography
            variant="h5"
            sx={{
              fontSize: { md: "24px", sm: "20px", xs: "20px" },
              fontWeight: 500,
              color: Colors.primary,
              display: {
                md: "block",
                sm: "none",
                xs: "none",
              },
            }}
          >
            {steps[activeStep]}
          </Typography>
          <Box
            onClick={() => {
              window.location.reload();
            }}
            sx={{ mr: 2.5, cursor: "pointer", display: "flex" }}
          >
            <HomeIcon
              sx={{
                color: Colors.primary,
                fontSize: { md: "30px", sm: "20px", xs: "20px" },
              }}
            />
          </Box>
        </Box>
        {/* Home Icon, Back Button Logo */}

        {/* Stepper */}
        <Grid container>
          <Grid item md={12} width={"100%"}>
            <Stepper
              alternativeLabel={true}
              activeStep={activeStep}
              connector={<QontoConnector />}
              sx={{ pt: "5px" }}
            >
              {steps.map((label, i) => (
                <Step key={label}>
                  <StepLabel
                    sx={{
                      ".MuiStepLabel-labelContainer span": {
                        fontSize: {
                          md: "16px !important",
                          sm: "12px !important",
                          xs: "7px !important",
                        },
                        marginTop: "8px !important",
                        cursor: "pointer",
                      },
                    }}
                    StepIconComponent={QontoStepIcon}
                    onClick={() => {
                      const value = i;
                      const pricing_type = selectedService?.pricing_type;
                      const name = getValues("name");
                      const contact = getValues("contact");
                      const email = getValues("email");
                      const address = getValues("address1");

                      if (value > 0 && !selectedService) {
                        messageHandler("Please select a service");
                      } else if (pricing_type === "Contact us" && value == 2) {
                        setActiveStep(2);
                      } else if (pricing_type !== "Contact us" && value == 1) {
                        setActiveStep(1)
                      }

                      if (activeStep == 1 && pricing_type !== "Contact us" && selectedDate.length == 0) {
                        if (value == 2) {
                          messageHandler("Please select a date");
                        }
                      }

                      if (activeStep == 1 && pricing_type == "hour" && selectedDate.length > 0 && value == 2) {
                        setActiveStep(2)
                      } else if (activeStep == 1 && pricing_type == "starting_from" && selectedDate.length > 0 &&
                        selectedPackage && Object.keys(selectedPackage)?.length == 0 && value == 2) {
                        messageHandler("Please select a package");
                      } else if (activeStep == 1 && pricing_type == "starting_from" && selectedDate.length > 0 &&
                        selectedPackage && Object.keys(selectedPackage)?.length > 0 && value == 2) {
                        setActiveStep(2);
                      }

                      if (activeStep == 2 && name == "" && contact == "" && email == "" && address == "") {
                        if (value == 3) {
                          messageHandler("Please provide details")
                        }
                      } else if (activeStep == 2 && pricing_type !== "Contact us" && name !== "" && contact !== "" &&
                        email !== "" && address !== "") {
                        if (value == 3) {
                          setActiveStep(3);
                        }
                      }

                      if (pricing_type == "hour" && selectedDate.length > 0 && name !== "" && contact !== "" &&
                        email !== "" && address !== "") {
                        setActiveStep(value);
                      } else if (pricing_type == "starting_from" && selectedDate.length > 0 && name !== "" &&
                        contact !== "" && email !== "" && address !== "" && selectedPackage &&
                        Object.keys(selectedPackage).length > 0) {
                        setActiveStep(value);
                      }

                      if (value < activeStep) {
                        if (pricing_type !== "Contact us") {
                          setActiveStep(value);
                        } else if (pricing_type == "Contact us" && value == 0) {
                          setActiveStep(0);
                        }
                      }

                      if (activeStep == 4 && response !== "" && value <= activeStep) {
                        window.location.reload();
                      }
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
        {/* Stepper */}

        {/* Frequency */}
        <Box
          sx={{
            width: "80%",
            height: "40px",
            position: "absolute",
            top: { xs: "115px", sm: "175px" },
            left: "10%",
            zIndex: 999,
            display: { md: "none", sm: activeStep === 1 ? "block" : "none", xs: activeStep === 1 ? "block" : "none" }
          }}
        >
          <Box sx={{ width: "100%", height: "100%" }}>
            <Tabs
              aria-label="basic tabs example"
              onChange={handleTabChange}
              value={selectedFrequency}
              indicatorColor={Colors.primary}
              sx={{ height: "100%" }}
              TabIndicatorProps={{
                style: {
                  left: `${indicatorPosition}px`,
                  width: 0,
                  height: 0,
                  borderLeft: "10px solid transparent",
                  borderRight: "10px solid transparent",
                  borderTop: `8px solid ${Colors.primary}`, // Set the color you want for the triangle
                  transform: "translateY(0px)",
                  position: "relative",
                  display: "block",
                  transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
              }}
            >
              {frequencies.map((frequency, i) => (
                <Tab
                  key={i}
                  label={frequency}
                  sx={{
                    p: 1,
                    width: "50%",
                    backgroundColor:
                      selectedFrequency === i ? Colors.primary : Colors.white,
                    color:
                      selectedFrequency === i
                        ? `${Colors.white} !important`
                        : `${Colors.primary} !important`,
                    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    boxShadow: "0px 3px 6px 0px #00000029",
                    fontSize: "12px",
                    minHeight: 0,
                    height: "100%",
                  }}
                />
              ))}
            </Tabs>
          </Box>
        </Box>
        {/* Frequency */}
      </Box>
      {/* Header */}
      <Container>
        <Box
          sx={{
            pb: {
              md: "20px",
              sm: activeStep === 1 ? "78px" : "50px",
              xs: activeStep === 1 ? "78px" : "50px"
            }
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              pt: {
                md: "200px",
                sm: "220px",
                xs: activeStep === 1 ? "150px" : "103px",
              },
            }}
            justifyContent={"center"}
          >
            {activeStep === steps.length ? (
              <Grid item md={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    gap: "20px",
                  }}
                >
                  <Typography variant="h5">
                    Thank you for your order!
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#828282", textAlign: "center" }}
                  >
                    Your order has been received, and we appreciate your trust
                    in our servies. We'll be in touch shortly for the
                    confirmation details.
                  </Typography>
                  <CheckCircleOutline
                    sx={{ fontSize: "48px", color: Colors.primary }}
                  />
                  <Button
                    variant="contained"
                    onClick={() => window.location.reload()}
                    // LinkComponent={"a"}
                    // href="https://cocoprimecleaning.ae/"
                    sx={{
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      ":hover": {
                        backgroundColor: Colors.secondary,
                      },
                    }}
                  >
                    Go to Services
                  </Button>
                </Box>
              </Grid>
            ) : (
              <Fragment>
                <Grid item md={12} sm={12} xs={12}>
                  <Box sx={{ width: "100%" }}>
                    <Grid container spacing={2} sx={{ mb: 1 }}>
                      {activeStep === 0 && (
                        <Grid item md={12}>
                          <Grid container spacing={1}>
                            {services.map((item, index) => (
                              <Grid
                                item
                                xs={6}
                                sm={4}
                                md={2.4}
                                lg={2.4}
                                key={index}
                              >
                                <ServiceCard
                                  image={Images[`${item.image}`]}
                                  title={item.name}
                                  desc={
                                    item.pricing_type == "Contact us"
                                      ? "Contact us"
                                      : item.pricing_type == "hour"
                                        ? `${item.price} AED per ${item.pricing_type}`
                                        : `Starting from ${item.price} AED`
                                  }
                                  onClick={() => {
                                    handleCardClick(item);
                                  }}
                                  selected={
                                    selectedService?.order === item?.order
                                  }
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      )}

                      {selectedService && (
                        <Fragment>
                          <Grid
                            item
                            xs={12}
                            sx={12}
                            md={activeStep === 0 ? 12 : 7.5}
                            component={"form"}
                            onSubmit={handleSubmit(submitForm)}
                          >
                            {activeStep === 1 && (
                              <Fragment>
                                <Grid container spacing={2}>
                                  {/* Select Frequency */}
                                  {/* <Grid item xs={12} sm={12} md={12}></Grid> */}
                                  {/* Select Frequency */}

                                  {/* Select Date */}
                                  <Grid item xs={12} sm={12} md={12}>
                                    <Grid
                                      container
                                      spacing={2}
                                      justifyContent={"center"}
                                    >
                                      <Grid item md={8}>
                                        {/* Frequency */}
                                        <Box
                                          sx={{
                                            display: { md: "block", sm: "none", xs: "none" },
                                            position: "fixed",
                                            width: { md: "38.9%", lg: "471.5px" },
                                            zIndex: 999,
                                            top: "174px",
                                          }}
                                        >
                                          <Box sx={{ width: "100%", height: "100%" }}>
                                            <Tabs
                                              aria-label="basic tabs example"
                                              onChange={handleTabChange}
                                              value={selectedFrequency}
                                              indicatorColor={Colors.primary}
                                              sx={{ height: "100%" }}
                                              TabIndicatorProps={{
                                                style: {
                                                  left: `${indicatorPosition1}px`,
                                                  width: 0,
                                                  height: 0,
                                                  borderLeft: "10px solid transparent",
                                                  borderRight: "10px solid transparent",
                                                  borderTop: `8px solid ${Colors.primary}`, // Set the color you want for the triangle
                                                  transform: "translateY(0px)",
                                                  position: "relative",
                                                  display: "block",
                                                  transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                                },
                                              }}
                                            >
                                              {frequencies.map((frequency, i) => (
                                                <Tab
                                                  key={i}
                                                  label={frequency}
                                                  sx={{
                                                    width: "50%",
                                                    backgroundColor:
                                                      selectedFrequency === i ? Colors.primary : Colors.white,
                                                    color:
                                                      selectedFrequency === i
                                                        ? `${Colors.white} !important`
                                                        : `${Colors.primary} !important`,
                                                    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                                    boxShadow: "0px 3px 6px 0px #00000029",
                                                    minHeight: 0,
                                                    height: "100%",
                                                  }}
                                                />
                                              ))}
                                            </Tabs>
                                          </Box>
                                        </Box>
                                        {/* Frequency */}
                                      </Grid>
                                      <Grid item md={8} sm={12} xs={12}>
                                        <Box width={"100%"}>
                                          <Calendar
                                            highlightToday={true}
                                            value={selectedFrequency === 0
                                              ? new Date(selectedDate[0])
                                              : selectedDate.map((date) => new Date(date))
                                            }
                                            multiple={selectedFrequency === 0
                                              ? false
                                              : true
                                            }
                                            onChange={(e) => {
                                              handleDateChange(e);
                                            }}
                                            className="multiDateCalendar"
                                            minDate={new Date()}
                                          />
                                          <Snackbar
                                            open={openSnackbar}
                                            autoHideDuration={3000}
                                            onClose={handleSnackbarClose}
                                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                          >
                                            <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                                              Cannot proceed with today's date
                                            </Alert>
                                          </Snackbar>
                                          <Box sx={{ display: selectedDate.length < 1 ? "block" : "none" }}>
                                            <FormHelperText error={selectedDate.length < 1 ? true : false}>
                                              Please select a date
                                            </FormHelperText>
                                          </Box>
                                        </Box>
                                      </Grid>
                                      {/* Select Package */}
                                      {selectedDate.length > 0 && selectedService?.name == "Deep Cleaning" ? (
                                        <Grid item md={8} sm={12} xs={12}>
                                          <Box
                                            sx={{
                                              p: 2,
                                              backgroundColor:
                                                Colors.white,
                                              boxShadow:
                                                "0px 3px 8px #91919140",
                                              borderRadius: "8px",
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "10px",
                                            }}
                                          >
                                            <Box>
                                              <Typography variant="caption"
                                                sx={{ color: selectedHomeType !== "" ? Colors.black : Colors.danger }}
                                              >
                                                Please select home type and package
                                              </Typography>
                                            </Box>
                                            <Tabs
                                              aria-label="basic tabs example"
                                              value={selectedHomeType}
                                              indicatorColor={Colors.primary}
                                              sx={{ height: "100%" }}
                                              onChange={handleHomeTypeChange}
                                            >
                                              {homeTypes.map((type, i) => (
                                                <Tab
                                                  key={i}
                                                  label={type}
                                                  sx={{
                                                    width: "50%",
                                                    backgroundColor:
                                                      selectedHomeType === i ? Colors.primary : Colors.white,
                                                    color:
                                                      selectedHomeType === i
                                                        ? `${Colors.white} !important`
                                                        : `${Colors.primary} !important`,
                                                    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                                    boxShadow: "0px 3px 6px 0px #00000029",
                                                    minHeight: 0,
                                                    height: "100%",
                                                  }}
                                                />
                                              ))}
                                            </Tabs>
                                            <FormControl size="small" error={!selectedPackage ? true : false}>
                                              <InputLabel id="selectPackage">Select Package</InputLabel>
                                              <Select
                                                disabled={selectedHomeType !== "" ? false : true}
                                                labelId="selectPackage"
                                                label="Select Package"
                                                fullWidth
                                                value={selectedPackage?.value ? selectedPackage?.value : ""}
                                                {...register("package", {
                                                  required: "Please select a package",
                                                  onChange: (e) => handlePackageChange(e)
                                                })}
                                              >
                                                {packages?.map((item, i) => (
                                                  <MenuItem key={i} value={item?.value}>
                                                    {item?.space}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                              <FormHelperText
                                                error={!selectedPackage ? true : false}
                                                sx={{ display: !selectedPackage ? "block" : "none" }}
                                              >
                                                {errors?.package?.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </Box>
                                        </Grid>
                                      ) : selectedDate.length > 0 && selectedService?.name == "AirBnB/Rental Home Cleaning" ? (
                                        <Grid item md={8} sm={12} xs={12}>
                                          <Box
                                            sx={{
                                              p: 2,
                                              backgroundColor:
                                                Colors.white,
                                              boxShadow:
                                                "0px 3px 8px #91919140",
                                              borderRadius: "8px",
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "10px",
                                            }}
                                          >
                                            <Box>
                                              <Typography variant="caption">
                                                Please select a package
                                              </Typography>
                                            </Box>
                                            <FormControl size="small" error={!selectedPackage ? true : false}>
                                              <InputLabel id="selectPackage">Select Package</InputLabel>
                                              <Select
                                                labelId="selectPackage"
                                                label="Select Package"
                                                fullWidth
                                                value={selectedPackage?.value ? selectedPackage?.value : ""}
                                                {...register("package", {
                                                  required: "Please select a package",
                                                  onChange: (e) => handlePackageChange(e)
                                                })}
                                              >
                                                {airbnbCleaningPackages?.map((item, i) => (
                                                  <MenuItem key={i} value={item?.value}>
                                                    {item?.space}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                              <FormHelperText
                                                error={!selectedPackage ? true : false}
                                                sx={{ display: !selectedPackage ? "block" : "none" }}
                                              >
                                                {errors?.package?.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </Box>
                                        </Grid>
                                      ) : <></>}
                                      {/* Select Package */}
                                      <Grid item md={8} sm={12} xs={12}>
                                        <Grid container spacing={2}>
                                          {selectedFrequency === 0 &&
                                            selectedDate.length === 1
                                            ? selectedDate.map((date, i) => (
                                              <Grid
                                                key={moment(date).format("YYYY-MM-DD")}
                                                item
                                                md={12}
                                                sm={12}
                                                xs={12}
                                              >
                                                <Box
                                                  sx={{
                                                    p: 2,
                                                    backgroundColor:
                                                      Colors.white,
                                                    boxShadow:
                                                      "0px 3px 8px #91919140",
                                                    borderRadius: "8px",
                                                  }}
                                                >
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "flex-start",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Typography>
                                                      {moment(date).format(
                                                        "MMM DD yyyy"
                                                      )}
                                                    </Typography>
                                                  </Box>
                                                  {/* Time slot, number of hours, sq. ft, maids and material */}
                                                  <Grid container spacing={2}>
                                                    {/* Time Slot */}
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={12}
                                                      md={12}
                                                    >
                                                      <Box>
                                                        {getValues(
                                                          `numberOfHours${moment(date).format("YYYY-MM-DD")}`
                                                        ) == 8 ? (
                                                          <Typography variant="caption">
                                                            Maid will arrive
                                                            in the morning
                                                          </Typography>
                                                        ) : (
                                                          <Typography variant="caption">
                                                            Select your Maid
                                                            Arrival Time
                                                          </Typography>
                                                        )}
                                                        {getValues(
                                                          `numberOfHours${moment(date).format("YYYY-MM-DD")}`
                                                        ) !== 8 && (
                                                            <FormControl
                                                              component={"fieldset"}
                                                              sx={{ width: "100%", }}
                                                            >
                                                              <RadioGroup
                                                                {...register
                                                                  (`timeSlot${moment(date).format("YYYY-MM-DD")}`,
                                                                    {
                                                                      onChange: (e) => handleTimeSlotChange(e, i),
                                                                      required: getValues(`timeSlot${moment(date).format("YYYY-MM-DD")}`) == null && getValues(`numberOfHours${moment(date).format("YYYY-MM-DD")}`) != 8
                                                                        ? "Please select a time" : false
                                                                    }
                                                                  )}
                                                                sx={{ width: "100%", }}
                                                              >
                                                                <Box
                                                                  sx={{
                                                                    display: "flex",
                                                                    gap: "8px",
                                                                    width: "100%",
                                                                  }}
                                                                >
                                                                  {selectedService?.name ===
                                                                    "AirBnB/Rental Home Cleaning"
                                                                    ? AirBNBtimeSlots.map(
                                                                      (slot, id) => (
                                                                        <FormControlLabel
                                                                          sx={{
                                                                            marginLeft: 0,
                                                                            marginRight: 0,
                                                                          }}
                                                                          key={
                                                                            id
                                                                          }
                                                                          value={
                                                                            slot.id
                                                                          }
                                                                          control={
                                                                            <Radio
                                                                              style={{
                                                                                display:
                                                                                  "none",
                                                                              }}
                                                                            />
                                                                          }
                                                                          label={
                                                                            <Box
                                                                              sx={{
                                                                                boxShadow:
                                                                                  "2px 3px 6px #C1C1C159",
                                                                                borderRadius:
                                                                                  "8px",
                                                                                p: 1,
                                                                                cursor:
                                                                                  "pointer",
                                                                                backgroundColor:
                                                                                  getValues(
                                                                                    `timeSlot${moment(date).format("YYYY-MM-DD")}`
                                                                                  ) ==
                                                                                    slot.slot
                                                                                    ? Colors.secondary
                                                                                    : Colors.white,
                                                                                color:
                                                                                  getValues(
                                                                                    `timeSlot${moment(date).format("YYYY-MM-DD")}`
                                                                                  ) ==
                                                                                    slot.slot
                                                                                    ? Colors.white
                                                                                    : Colors.black,
                                                                                transition:
                                                                                  "all .3s ease-in-out",
                                                                              }}
                                                                            >
                                                                              <Typography
                                                                                variant="caption"
                                                                                sx={{
                                                                                  fontSize:
                                                                                  {
                                                                                    md: "12px",
                                                                                    xs: "10px",
                                                                                  },
                                                                                }}
                                                                              >
                                                                                {
                                                                                  slot.arrivalTime
                                                                                }
                                                                              </Typography>
                                                                            </Box>
                                                                          }
                                                                        />
                                                                      )
                                                                    )
                                                                    : timeSlots.map(
                                                                      (
                                                                        slot,
                                                                        id
                                                                      ) => (
                                                                        <FormControlLabel
                                                                          sx={{
                                                                            "& .MuiFormControlLabel-label": {
                                                                              width: "90%"
                                                                            },
                                                                            marginLeft: 0,
                                                                            marginRight: 0,
                                                                            width:
                                                                              "50%",
                                                                          }}
                                                                          key={
                                                                            id
                                                                          }
                                                                          value={
                                                                            slot.id
                                                                          }
                                                                          control={
                                                                            <Radio
                                                                              style={{
                                                                                display:
                                                                                  "none",
                                                                              }}
                                                                            />
                                                                          }
                                                                          label={
                                                                            <Box
                                                                              sx={{
                                                                                boxShadow:
                                                                                  "2px 3px 6px #C1C1C159",
                                                                                borderRadius:
                                                                                  "8px",
                                                                                textAlign:
                                                                                  "center",
                                                                                width:
                                                                                  "100%",
                                                                                p: 1,
                                                                                cursor:
                                                                                  "pointer",
                                                                                backgroundColor:
                                                                                  getValues(
                                                                                    `timeSlot${moment(date).format("YYYY-MM-DD")}`
                                                                                  ) ==
                                                                                    slot.slot
                                                                                    ? Colors.secondary
                                                                                    : Colors.white,
                                                                                color:
                                                                                  getValues(
                                                                                    `timeSlot${moment(date).format("YYYY-MM-DD")}`
                                                                                  ) ==
                                                                                    slot.slot
                                                                                    ? Colors.white
                                                                                    : Colors.black,
                                                                                transition:
                                                                                  "all .3s ease-in-out",
                                                                              }}
                                                                            >
                                                                              <Typography
                                                                                variant="caption"
                                                                                sx={{
                                                                                  fontSize:
                                                                                  {
                                                                                    md: "12px",
                                                                                    xs: "10px",
                                                                                  },
                                                                                }}
                                                                              >
                                                                                {
                                                                                  slot.slot
                                                                                }
                                                                              </Typography>
                                                                              <Typography
                                                                                variant="caption"
                                                                                sx={{
                                                                                  fontSize:
                                                                                  {
                                                                                    md: "12px",
                                                                                    xs: "10px",
                                                                                  },
                                                                                }}
                                                                              >
                                                                                {
                                                                                  slot.arrivalTime
                                                                                }
                                                                              </Typography>
                                                                            </Box>
                                                                          }
                                                                        />
                                                                      )
                                                                    )}
                                                                </Box>
                                                              </RadioGroup>
                                                              <FormHelperText
                                                                error={
                                                                  errors[
                                                                  `timeSlot${i}`
                                                                  ] && true
                                                                }
                                                              >
                                                                {
                                                                  errors[
                                                                    `timeSlot${i}`
                                                                  ]?.message
                                                                }
                                                              </FormHelperText>
                                                            </FormControl>
                                                          )}
                                                      </Box>
                                                    </Grid>
                                                    {/* Time Slot */}

                                                    {/* Number Of Hours */}
                                                    <Grid
                                                      item
                                                      xs={6}
                                                      sm={6}
                                                      md={6}
                                                      display={
                                                        selectedService?.name ===
                                                          "Home Cleaning" ||
                                                          selectedService?.name ===
                                                          "Folding & Ironing Service"
                                                          ? "block"
                                                          : "none"
                                                      }
                                                    >
                                                      <Box>
                                                        <Typography variant="caption">
                                                          Number of hours
                                                        </Typography>
                                                        <FormControl
                                                          component={
                                                            "fieldset"
                                                          }
                                                          sx={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <RadioGroup
                                                            {...register(
                                                              `numberOfHours${moment(date).format("YYYY-MM-DD")}`,
                                                              {
                                                                onChange: (e) => handleNumOfHoursChange(e, i),
                                                                required: selectedService?.pricing_type == "hour" && getValues(`numberOfHours${moment(date).format("YYYY-MM-DD")}`) == null
                                                                  ? "Please select number of hours"
                                                                  : false,
                                                              }
                                                            )}
                                                            sx={{
                                                              flexDirection: "row",
                                                              gap: "8px",
                                                            }}
                                                          >
                                                            {numberOfHours.map(
                                                              (num, id) => {
                                                                if (
                                                                  (selectedService.name === "Home Cleaning" &&
                                                                    (num === 4 || num === 6 || num === 8)) ||
                                                                  selectedService.name !==
                                                                  "Home Cleaning"
                                                                ) {
                                                                  return (
                                                                    <FormControlLabel
                                                                      key={id}
                                                                      value={num}
                                                                      sx={{
                                                                        marginLeft: 0,
                                                                        marginRight: 0,
                                                                      }}
                                                                      control={
                                                                        <Radio
                                                                          style={{
                                                                            display: "none",
                                                                          }}
                                                                        />
                                                                      }
                                                                      label={
                                                                        <Typography
                                                                          variant="caption"
                                                                          sx={{
                                                                            width: "35px",
                                                                            height: "35px",
                                                                            borderRadius: "50%",
                                                                            backgroundColor: getValues(
                                                                              `numberOfHours${moment(date).format("YYYY-MM-DD")}`) == num
                                                                              ? Colors.secondary
                                                                              : Colors.grey,
                                                                            color:
                                                                              getValues(`numberOfHours${moment(date).format("YYYY-MM-DD")}`) == num
                                                                                ? Colors.white
                                                                                : Colors.black,
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            cursor: "pointer",
                                                                            transition: "all .3s ease-in-out",
                                                                            fontWeight: 600,
                                                                          }}
                                                                        >
                                                                          {num}
                                                                        </Typography>
                                                                      }
                                                                    />
                                                                  );
                                                                } else {
                                                                  return null;
                                                                }
                                                              }
                                                            )}
                                                          </RadioGroup>
                                                          <FormHelperText
                                                            error={
                                                              errors[
                                                              `numberOfHours${i}`
                                                              ] && true
                                                            }
                                                          >
                                                            {
                                                              errors[
                                                                `numberOfHours${i}`
                                                              ]?.message
                                                            }
                                                          </FormHelperText>
                                                        </FormControl>
                                                      </Box>
                                                    </Grid>
                                                    {/* Number Of Hours */}

                                                    {/* Number Of Maids */}
                                                    <Grid
                                                      item
                                                      xs={6}
                                                      sm={6}
                                                      md={6}
                                                      display={
                                                        selectedService?.name ===
                                                          "Home Cleaning" ||
                                                          selectedService?.name ===
                                                          "Folding & Ironing Service"
                                                          ? "block"
                                                          : "none"
                                                      }
                                                    >
                                                      <Box>
                                                        <Typography variant="caption">
                                                          Number of maids
                                                        </Typography>
                                                        <Box
                                                          sx={{
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                            border: `1px solid ${Colors.grey}`,
                                                            borderRadius:
                                                              "6px",
                                                            height: "35px",
                                                          }}
                                                        >
                                                          <Button
                                                            variant="outlined"
                                                            onClick={() =>
                                                              handleDecrement(
                                                                i
                                                              )
                                                            }
                                                            sx={{
                                                              minWidth: 0,
                                                              width: "66px",
                                                              border: "none",
                                                              ":hover": {
                                                                border:
                                                                  "none",
                                                              },
                                                            }}
                                                          >
                                                            -
                                                          </Button>
                                                          <TextField
                                                            defaultValue={1}
                                                            type="number"
                                                            sx={{
                                                              textAlign:
                                                                "center",
                                                              "& fieldset": {
                                                                border:
                                                                  "none",
                                                              },
                                                            }}
                                                            inputProps={{
                                                              readOnly: true,
                                                              style: {
                                                                padding: 0,
                                                                textAlign:
                                                                  "center",
                                                              },
                                                            }}
                                                            {...register(
                                                              `numberOfMaids${moment(date).format("YYYY-MM-DD")}`
                                                            )}
                                                          />
                                                          <Button
                                                            variant="outlined"
                                                            onClick={() =>
                                                              handleIncrement(
                                                                i
                                                              )
                                                            }
                                                            sx={{
                                                              minWidth: 0,
                                                              width: "66px",
                                                              border: "none",
                                                              ":hover": {
                                                                border:
                                                                  "none",
                                                              },
                                                            }}
                                                          >
                                                            +
                                                          </Button>
                                                        </Box>
                                                      </Box>
                                                    </Grid>
                                                    {/* Number Of Maids */}

                                                    {/* Select Material */}
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={12}
                                                      md={12}
                                                      display={
                                                        selectedService?.name ===
                                                          "Home Cleaning" ||
                                                          selectedService?.name ===
                                                          "Folding & Ironing Service"
                                                          ? "block"
                                                          : "none"
                                                      }
                                                    >
                                                      <Box>
                                                        <Typography variant="caption" sx={{ display: "block" }}>
                                                          {selectedService?.name ===
                                                            "Home Cleaning"
                                                            ? " Do you want us to bring cleaning materials?"
                                                            : "Do you want us to bring our own supplies?"}
                                                        </Typography>
                                                        <FormControl
                                                          sx={{
                                                            boxShadow:
                                                              "0px 2px 3px #00000029",
                                                            width: "134px",
                                                            p: 1,
                                                            borderRadius:
                                                              "28px",
                                                          }}
                                                        >
                                                          <RadioGroup
                                                            {...register(
                                                              `material${moment(date).format("YYYY-MM-DD")}`,
                                                              {
                                                                onChange: (e) => handleMaterialChange(e, i),
                                                                required: selectedService?.pricing_type == "hour" &&
                                                                  getValues(`material${moment(date).format("YYYY-MM-DD")}`) == null
                                                                  ? "Please select an answer"
                                                                  : false
                                                              }
                                                            )}
                                                            value={getValues(
                                                              `material${moment(date).format("YYYY-MM-DD")}`
                                                            )}
                                                            sx={{
                                                              flexDirection:
                                                                "row",
                                                              justifyContent:
                                                                "space-between",
                                                            }}
                                                          >
                                                            {[
                                                              "Yes",
                                                              "No",
                                                            ].map(
                                                              (bool, id) => (
                                                                <FormControlLabel
                                                                  key={id}
                                                                  value={bool}
                                                                  sx={{
                                                                    marginLeft: 0,
                                                                    marginRight: 0,
                                                                  }}
                                                                  control={
                                                                    <Radio
                                                                      style={{
                                                                        display:
                                                                          "none",
                                                                      }}
                                                                    />
                                                                  }
                                                                  label={
                                                                    <Box
                                                                      sx={{
                                                                        borderRadius:
                                                                          "20px",
                                                                        p: "6px 24px",
                                                                        backgroundColor:
                                                                          getValues(
                                                                            `material${moment(date).format("YYYY-MM-DD")}`
                                                                          ) ==
                                                                            bool
                                                                            ? Colors.secondary
                                                                            : Colors.white,
                                                                        color:
                                                                          getValues(
                                                                            `material${moment(date).format("YYYY-MM-DD")}`
                                                                          ) ==
                                                                            bool
                                                                            ? Colors.white
                                                                            : Colors.black,
                                                                        transition:
                                                                          "all .3s ease-in-out",
                                                                      }}
                                                                    >
                                                                      <Typography
                                                                        sx={{
                                                                          fontSize:
                                                                            "12px",
                                                                          textAlign:
                                                                            "center",
                                                                        }}
                                                                      >
                                                                        {bool}
                                                                      </Typography>
                                                                    </Box>
                                                                  }
                                                                />
                                                              )
                                                            )}
                                                          </RadioGroup>
                                                        </FormControl>
                                                        <FormHelperText
                                                          error={
                                                            errors[
                                                            `material${i}`
                                                            ] && true
                                                          }
                                                        >
                                                          {
                                                            errors[
                                                              `material${i}`
                                                            ]?.message
                                                          }
                                                        </FormHelperText>
                                                      </Box>
                                                    </Grid>
                                                    {/* Select Material */}
                                                  </Grid>
                                                  {/* Time slot, number of hours, sq. ft, maids and material */}
                                                </Box>
                                              </Grid>
                                            ))
                                            : selectedDate.length > 0 &&
                                            selectedDate.map((date, i) => (
                                              <Grid
                                                item
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                key={moment(date).format("YYYY-MM-DD")}
                                              >
                                                <Box
                                                  sx={{
                                                    p: 2,
                                                    backgroundColor:
                                                      Colors.white,
                                                    boxShadow:
                                                      "0px 3px 8px #91919140",
                                                    borderRadius: "8px",
                                                  }}
                                                >
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Typography>
                                                      {moment(date).format(
                                                        "MMM DD yyyy"
                                                      )}
                                                    </Typography>
                                                    <IconButton
                                                      onClick={() =>
                                                        handleDateRemove(date)
                                                      }
                                                    >
                                                      <Close
                                                        sx={{
                                                          fontSize: "18px",
                                                          color:
                                                            Colors.danger,
                                                        }}
                                                      />
                                                    </IconButton>
                                                  </Box>
                                                  {/* Time slot, number of hours, sq. ft, maids and material */}
                                                  <Grid container spacing={2}>
                                                    {/* Time Slot */}
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={12}
                                                      md={12}
                                                    >
                                                      <Box>
                                                        {getValues(
                                                          `numberOfHours${moment(date).format("YYYY-MM-DD")}`
                                                        ) == 8 ? (
                                                          <Typography variant="caption">
                                                            Maid will arrive
                                                            in the morning
                                                          </Typography>
                                                        ) : (
                                                          <Typography variant="caption">
                                                            Select your Maid
                                                            Arrival Time
                                                          </Typography>
                                                        )}
                                                        {getValues(
                                                          `numberOfHours${moment(date).format("YYYY-MM-DD")}`
                                                        ) !== 8 && (
                                                            <FormControl
                                                              component={
                                                                "fieldset"
                                                              }
                                                              sx={{
                                                                width: "100%",
                                                              }}
                                                            >
                                                              <RadioGroup
                                                                {...register(
                                                                  `timeSlot${moment(date).format("YYYY-MM-DD")}`,
                                                                  {
                                                                    onChange: (e) => handleTimeSlotChange(e, i),
                                                                    required:
                                                                      getValues(`timeSlot${moment(date).format("YYYY-MM-DD")}`) == null && getValues(`numberOfHours${moment(date).format("YYYY-MM-DD")}`) != 8
                                                                        ? "Please select a time" : false
                                                                  }
                                                                )}
                                                                sx={{
                                                                  width: "100%",
                                                                }}
                                                              >
                                                                <Box
                                                                  sx={{
                                                                    display:
                                                                      "flex",
                                                                    gap: "8px",
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                >
                                                                  {selectedService?.name ===
                                                                    "AirBnB/Rental Home Cleaning"
                                                                    ? AirBNBtimeSlots.map(
                                                                      (
                                                                        slot,
                                                                        id
                                                                      ) => (
                                                                        <FormControlLabel
                                                                          sx={{
                                                                            marginLeft: 0,
                                                                            marginRight: 0,
                                                                          }}
                                                                          key={
                                                                            id
                                                                          }
                                                                          value={
                                                                            slot.id
                                                                          }
                                                                          control={
                                                                            <Radio
                                                                              style={{
                                                                                display:
                                                                                  "none",
                                                                              }}
                                                                            />
                                                                          }
                                                                          label={
                                                                            <Box
                                                                              sx={{
                                                                                boxShadow:
                                                                                  "2px 3px 6px #C1C1C159",
                                                                                borderRadius:
                                                                                  "8px",
                                                                                p: 1,
                                                                                cursor:
                                                                                  "pointer",
                                                                                backgroundColor:
                                                                                  getValues(
                                                                                    `timeSlot${moment(date).format("YYYY-MM-DD")}`
                                                                                  ) ==
                                                                                    slot.slot
                                                                                    ? Colors.secondary
                                                                                    : Colors.white,
                                                                                color:
                                                                                  getValues(
                                                                                    `timeSlot${moment(date).format("YYYY-MM-DD")}`
                                                                                  ) ==
                                                                                    slot.slot
                                                                                    ? Colors.white
                                                                                    : Colors.black,
                                                                                transition:
                                                                                  "all .3s ease-in-out",
                                                                              }}
                                                                            >
                                                                              <Typography
                                                                                variant="caption"
                                                                                sx={{
                                                                                  fontSize:
                                                                                  {
                                                                                    md: "12px",
                                                                                    xs: "10px",
                                                                                  },
                                                                                }}
                                                                              >
                                                                                {
                                                                                  slot.arrivalTime
                                                                                }
                                                                              </Typography>
                                                                            </Box>
                                                                          }
                                                                        />
                                                                      )
                                                                    )
                                                                    : timeSlots.map(
                                                                      (
                                                                        slot,
                                                                        id
                                                                      ) => (
                                                                        <FormControlLabel
                                                                          sx={{
                                                                            "& .MuiFormControlLabel-label": {
                                                                              width: "90%"
                                                                            },
                                                                            marginLeft: 0,
                                                                            marginRight: 0,
                                                                            width:
                                                                              "50%",
                                                                          }}
                                                                          key={
                                                                            id
                                                                          }
                                                                          value={
                                                                            slot.id
                                                                          }
                                                                          control={
                                                                            <Radio
                                                                              style={{
                                                                                display:
                                                                                  "none",
                                                                              }}
                                                                            />
                                                                          }
                                                                          label={
                                                                            <Box
                                                                              sx={{
                                                                                boxShadow:
                                                                                  "2px 3px 6px #C1C1C159",
                                                                                borderRadius:
                                                                                  "8px",
                                                                                textAlign:
                                                                                  "center",
                                                                                width:
                                                                                  "100%",
                                                                                p: 1,
                                                                                cursor:
                                                                                  "pointer",
                                                                                backgroundColor:
                                                                                  getValues(
                                                                                    `timeSlot${moment(date).format("YYYY-MM-DD")}`
                                                                                  ) ==
                                                                                    slot.slot
                                                                                    ? Colors.secondary
                                                                                    : Colors.white,
                                                                                color:
                                                                                  getValues(
                                                                                    `timeSlot${moment(date).format("YYYY-MM-DD")}`
                                                                                  ) ==
                                                                                    slot.slot
                                                                                    ? Colors.white
                                                                                    : Colors.black,
                                                                                transition:
                                                                                  "all .3s ease-in-out",
                                                                              }}
                                                                            >
                                                                              <Typography
                                                                                sx={{
                                                                                  fontSize:
                                                                                  {
                                                                                    md: "12px",
                                                                                    xs: "10px",
                                                                                  },
                                                                                }}
                                                                              >
                                                                                {
                                                                                  slot.slot
                                                                                }
                                                                                {
                                                                                  slot.arrivalTime
                                                                                }
                                                                              </Typography>
                                                                            </Box>
                                                                          }
                                                                        />
                                                                      )
                                                                    )}
                                                                </Box>
                                                              </RadioGroup>
                                                              <FormHelperText
                                                                error={
                                                                  errors[
                                                                  `timeSlot${i}`
                                                                  ] && true
                                                                }
                                                              >
                                                                {
                                                                  errors[
                                                                    `timeSlot${i}`
                                                                  ]?.message
                                                                }
                                                              </FormHelperText>
                                                            </FormControl>
                                                          )}
                                                      </Box>
                                                    </Grid>
                                                    {/* Time Slot */}

                                                    {/* Number Of Hours */}
                                                    <Grid
                                                      item
                                                      xs={6}
                                                      sm={6}
                                                      md={6}
                                                      display={
                                                        selectedService?.name ===
                                                          "Home Cleaning" ||
                                                          selectedService?.name ===
                                                          "Folding & Ironing Service"
                                                          ? "block"
                                                          : "none"
                                                      }
                                                    >
                                                      <Box>
                                                        <Typography variant="caption">
                                                          Number of hours
                                                        </Typography>
                                                        <FormControl
                                                          component={
                                                            "fieldset"
                                                          }
                                                          sx={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <RadioGroup
                                                            {...register(
                                                              `numberOfHours${moment(date).format("YYYY-MM-DD")}`,
                                                              {
                                                                onChange: (e) => handleNumOfHoursChange(e, i),
                                                                required: selectedService?.pricing_type == "hour" && getValues(`numberOfHours${moment(date).format("YYYY-MM-DD")}`) == null
                                                                  ? "Please select number of hours"
                                                                  : false,
                                                              }
                                                            )}
                                                            sx={{
                                                              flexDirection:
                                                                "row",
                                                              gap: "8px",
                                                            }}
                                                          >
                                                            {numberOfHours.map(
                                                              (num, id) => {
                                                                if (
                                                                  (selectedService.name === "Home Cleaning" &&
                                                                    (num === 4 || num === 6 || num === 8)) ||
                                                                  selectedService.name !==
                                                                  "Home Cleaning"
                                                                ) {
                                                                  return (
                                                                    <FormControlLabel
                                                                      key={id}
                                                                      value={num}
                                                                      sx={{
                                                                        marginLeft: 0,
                                                                        marginRight: 0,
                                                                      }}
                                                                      control={
                                                                        <Radio
                                                                          style={{
                                                                            display: "none",
                                                                          }}
                                                                        />
                                                                      }
                                                                      label={
                                                                        <Typography
                                                                          variant="caption"
                                                                          sx={{
                                                                            width: "35px",
                                                                            height: "35px",
                                                                            borderRadius: "50%",
                                                                            backgroundColor:
                                                                              getValues(`numberOfHours${moment(date).format("YYYY-MM-DD")}`) == num
                                                                                ? Colors.secondary
                                                                                : Colors.grey,
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            color:
                                                                              getValues(`numberOfHours${moment(date).format("YYYY-MM-DD")}`) == num
                                                                                ? Colors.white
                                                                                : Colors.black,
                                                                            cursor: "pointer",
                                                                            transition: "all .3s ease-in-out",
                                                                            fontWeight: 600,
                                                                          }}
                                                                        >
                                                                          {num}
                                                                        </Typography>
                                                                      }
                                                                    />
                                                                  );
                                                                } else {
                                                                  return null;
                                                                }
                                                              }
                                                            )}
                                                          </RadioGroup>
                                                          <FormHelperText
                                                            error={errors[`numberOfHours${i}`] && true}
                                                          >
                                                            {errors[`numberOfHours${i}`]?.message}
                                                          </FormHelperText>
                                                        </FormControl>
                                                      </Box>
                                                    </Grid>
                                                    {/* Number Of Hours */}

                                                    {/* Number Of Maids */}
                                                    <Grid
                                                      item
                                                      xs={6}
                                                      sm={6}
                                                      md={6}
                                                      display={
                                                        selectedService?.name ===
                                                          "Home Cleaning" ||
                                                          selectedService?.name ===
                                                          "Folding & Ironing Service"
                                                          ? "block"
                                                          : "none"
                                                      }
                                                    >
                                                      <Box>
                                                        <Typography variant="caption">
                                                          Number of maids
                                                        </Typography>
                                                        <Box
                                                          sx={{
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                            border: `1px solid ${Colors.grey}`,
                                                            borderRadius:
                                                              "6px",
                                                            height: "35px",
                                                          }}
                                                        >
                                                          <Button
                                                            variant="outlined"
                                                            onClick={() =>
                                                              handleDecrement(
                                                                i
                                                              )
                                                            }
                                                            sx={{
                                                              minWidth: 0,
                                                              width: "66px",
                                                              border: "none",
                                                              ":hover": {
                                                                border:
                                                                  "none",
                                                              },
                                                            }}
                                                          >
                                                            -
                                                          </Button>
                                                          <TextField
                                                            defaultValue={1}
                                                            type="number"
                                                            sx={{
                                                              textAlign:
                                                                "center",
                                                              "& fieldset": {
                                                                border:
                                                                  "none",
                                                              },
                                                            }}
                                                            inputProps={{
                                                              readOnly: true,
                                                              style: {
                                                                padding: 0,
                                                                textAlign:
                                                                  "center",
                                                              },
                                                            }}
                                                            {...register(
                                                              `numberOfMaids${moment(date).format("YYYY-MM-DD")}`
                                                            )}
                                                          />
                                                          <Button
                                                            variant="outlined"
                                                            onClick={() =>
                                                              handleIncrement(
                                                                i
                                                              )
                                                            }
                                                            sx={{
                                                              minWidth: 0,
                                                              width: "66px",
                                                              border: "none",
                                                              ":hover": {
                                                                border:
                                                                  "none",
                                                              },
                                                            }}
                                                          >
                                                            +
                                                          </Button>
                                                        </Box>
                                                      </Box>
                                                    </Grid>
                                                    {/* Number Of Maids */}

                                                    {/* Select Material */}
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={12}
                                                      md={12}
                                                      display={
                                                        selectedService?.name ===
                                                          "Home Cleaning" ||
                                                          selectedService?.name ===
                                                          "Folding & Ironing Service"
                                                          ? "block"
                                                          : "none"
                                                      }
                                                    >
                                                      <Box>
                                                        <Typography variant="caption" sx={{ display: "block" }}>
                                                          {selectedService?.name ===
                                                            "Home Cleaning"
                                                            ? " Do you want us to bring cleaning materials?"
                                                            : "Do you want us to bring our own supplies?"}
                                                        </Typography>
                                                        <FormControl
                                                          sx={{
                                                            boxShadow:
                                                              "0px 2px 3px #00000029",
                                                            width: "134px",
                                                            p: 1,
                                                            borderRadius:
                                                              "28px",
                                                          }}
                                                        >
                                                          <RadioGroup
                                                            {...register(
                                                              `material${moment(date).format("YYYY-MM-DD")}`,
                                                              {
                                                                onChange: (e) => handleMaterialChange(e, i),
                                                                required: selectedService?.pricing_type == "hour" &&
                                                                  getValues(`material${moment(date).format("YYYY-MM-DD")}`) == null
                                                                  ? "Please select an answer"
                                                                  : false
                                                              }
                                                            )}
                                                            value={getValues(
                                                              `material${moment(date).format("YYYY-MM-DD")}`
                                                            )}
                                                            sx={{
                                                              flexDirection:
                                                                "row",
                                                              justifyContent:
                                                                "space-between",
                                                            }}
                                                          >
                                                            {[
                                                              "Yes",
                                                              "No",
                                                            ].map(
                                                              (bool, id) => (
                                                                <FormControlLabel
                                                                  key={id}
                                                                  value={bool}
                                                                  sx={{
                                                                    marginLeft: 0,
                                                                    marginRight: 0,
                                                                  }}
                                                                  control={
                                                                    <Radio
                                                                      style={{
                                                                        display:
                                                                          "none",
                                                                      }}
                                                                    />
                                                                  }
                                                                  label={
                                                                    <Box
                                                                      sx={{
                                                                        borderRadius:
                                                                          "20px",
                                                                        p: "6px 24px",
                                                                        backgroundColor:
                                                                          getValues(
                                                                            `material${moment(date).format("YYYY-MM-DD")}`
                                                                          ) ==
                                                                            bool
                                                                            ? Colors.secondary
                                                                            : Colors.white,
                                                                        color:
                                                                          getValues(
                                                                            `material${moment(date).format("YYYY-MM-DD")}`
                                                                          ) ==
                                                                            bool
                                                                            ? Colors.white
                                                                            : Colors.black,
                                                                        transition:
                                                                          "all .3s ease-in-out",
                                                                      }}
                                                                    >
                                                                      <Typography
                                                                        sx={{
                                                                          fontSize:
                                                                            "12px",
                                                                          textAlign:
                                                                            "center",
                                                                        }}
                                                                      >
                                                                        {bool}
                                                                      </Typography>
                                                                    </Box>
                                                                  }
                                                                />
                                                              )
                                                            )}
                                                          </RadioGroup>
                                                        </FormControl>
                                                        <FormHelperText
                                                          error={
                                                            errors[
                                                            `material${i}`
                                                            ] && true
                                                          }
                                                        >
                                                          {
                                                            errors[
                                                              `material${i}`
                                                            ]?.message
                                                          }
                                                        </FormHelperText>
                                                      </Box>
                                                    </Grid>
                                                    {/* Select Material */}
                                                  </Grid>
                                                  {/* Time slot, number of hours, sq. ft, maids and material */}
                                                </Box>
                                              </Grid>
                                            ))}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  {/* Select Date */}
                                </Grid>
                              </Fragment>
                            )}

                            {activeStep === 2 && (
                              <Box component={Paper} sx={{ p: 5, mb: "5px" }}>
                                <Box sx={{ mb: 2 }}>
                                  <Typography
                                    variant="h4"
                                    sx={{ fontSize: { xs: "24px" } }}
                                  >
                                    Customer Details
                                  </Typography>
                                </Box>
                                <Grid container spacing={2}>
                                  <Grid item md={6} sm={12} xs={12}>
                                    <InputField
                                      fullWidth
                                      variant="outlined"
                                      placeholder="Name"
                                      type="text"
                                      size="normal"
                                      register={register("name", {
                                        required: "Please enter you name",
                                        pattern: {
                                          value: /^[A-Za-z ]+$/,
                                          message: "Please enter name in text format"
                                        }
                                      })}
                                      error={errors?.name}
                                      helperText={errors?.name?.message}
                                      icon={<Person />}
                                    />
                                  </Grid>

                                  <Grid item md={6} sm={12} xs={12}>
                                    <InputField
                                      fullWidth
                                      variant="outlined"
                                      placeholder="Contact Number"
                                      type="text"
                                      size="normal"
                                      inputProps={{
                                        inputMode: "numeric",
                                        pattern: "^[0-9]+$",
                                        maxLength: 10,
                                        minLength: 9,
                                      }}
                                      register={register("contact", {
                                        required:
                                          "Please enter your contact number",
                                        pattern: {
                                          value: "^[0-9]+$",
                                          message:
                                            "Please enter a valid number",
                                        },
                                        maxLength: 10,
                                        minLength: 9,
                                      })}
                                      error={errors?.contact}
                                      helperText={errors?.contact?.message}
                                      code={"+971"}
                                      icon={<LocalPhone />}
                                    />
                                  </Grid>

                                  <Grid item md={12} sm={12} xs={12}>
                                    <InputField
                                      fullWidth
                                      variant="outlined"
                                      placeholder="Email"
                                      type="email"
                                      size="normal"
                                      register={register("email", {
                                        required:
                                          "Please enter your email address",
                                      })}
                                      error={errors?.email}
                                      helperText={errors?.email?.message}
                                      icon={<Email />}
                                    />
                                  </Grid>

                                  <Grid item md={12} sm={12} xs={12}>
                                    <Box
                                      component={"div"}
                                      onClick={() => {
                                        setAddressFormDialog(true);
                                      }}
                                      onKeyUp={(e) => {
                                        if (e.key === "Tab") {
                                          setAddressFormDialog(true);
                                        }
                                      }}
                                      tabIndex={0}
                                    >
                                      <InputField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Address"
                                        value={"addressData?.address"}
                                        type="text"
                                        size="normal"
                                        readOnly={true}
                                        register={register("address1", { required: "Please enter address" })}
                                        error={errors?.address1}
                                        helperText={errors?.address1?.message}
                                        icon={<Villa />}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            )}

                            {activeStep === 3 && (
                              <Box component={Paper} sx={{ p: 4, mb: 2 }}>
                                <Box sx={{ mb: 2 }}>
                                  <Typography
                                    variant="h4"
                                    sx={{ fontSize: { xs: "24px" } }}
                                  >
                                    Payment Details
                                  </Typography>
                                </Box>
                                <Grid container spacing={2}>
                                  <Grid item md={12} width={"100%"}>
                                    <InputField
                                      fullWidth
                                      variant="outlined"
                                      placeholder="Additional Notes"
                                      type="text"
                                      multiline
                                      rows={3}
                                      register={register("notes")}
                                    />
                                  </Grid>

                                  <Grid item md={12} width={"100%"}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          onClick={() => {
                                            if (isChecked && open === false) {
                                              setIsChecked(false);
                                            }
                                            if (!isChecked) {
                                              handleCheckboxChange();
                                            }
                                          }}
                                          checked={isChecked}
                                        />
                                      }
                                      label={
                                        <Typography variant="caption">
                                          I have read and agree to the{" "}
                                          <b
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleCheckboxChange();
                                            }}
                                          >
                                            Terms and Conditions
                                          </b>
                                        </Typography>
                                      }
                                      {...register("termAndCondition", {
                                        required: !isChecked
                                          ? "Please check terms and conditions"
                                          : false,
                                      })}
                                    />
                                    <FormHelperText
                                      error={
                                        errors?.termAndCondition ? true : false
                                      }
                                      sx={{
                                        display: errors?.termAndCondition
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      {errors?.termAndCondition?.message}
                                    </FormHelperText>
                                    <Typography variant="caption" sx={{ display: "block" }}>
                                      <b>Note:&nbsp;</b>Please make sure that
                                      the front gate/lobby/building management
                                      is notified for the entrance pass of Coco
                                      Prime Cleaning Service Staff. Any
                                      additional documents or information
                                      required to enter the premises, please let
                                      us know beforehand.
                                    </Typography>
                                  </Grid>

                                  <Grid item md={12} width={"100%"}>
                                    <Box>
                                      <Box sx={{ py: "20px" }}>
                                        <Typography variant="subtitle2">
                                          Select payment method
                                        </Typography>
                                        <Box
                                          component={Paper}
                                          sx={{
                                            p: 1,
                                            borderRadius: "30px",
                                            minWidth: "100px",
                                            maxWidth: "fit-content",
                                            mt: 1,
                                          }}
                                        >
                                          <FormControl
                                            component={"fieldset"}
                                            {...register("paymentMethod", {
                                              required: !paymentMethod
                                                ? "Please select a payment method"
                                                : false,
                                            })}
                                            sx={{
                                              display: "flex",
                                              gap: "10px",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <Button
                                              variant="standart"
                                              sx={{
                                                fontSize: "12px",
                                                borderRadius: "22px",
                                                backgroundColor:
                                                  paymentMethod === "cash"
                                                    ? Colors.secondary
                                                    : Colors.white,
                                                color:
                                                  paymentMethod === "cash"
                                                    ? Colors.white
                                                    : Colors.primary,
                                                ":hover": {
                                                  backgroundColor:
                                                    paymentMethod === "cash"
                                                      ? Colors.secondary
                                                      : Colors.primary,
                                                  color: Colors.white,
                                                },
                                                transition:
                                                  "all .3s ease-in-out",
                                                px: "20px",
                                              }}
                                              onClick={() =>
                                                setPaymentMethod("cash")
                                              }
                                            >
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  gap: "5px",
                                                }}
                                              >
                                                <Payments
                                                  sx={{
                                                    fontSize: "20px",
                                                  }}
                                                />
                                                Cash
                                              </Box>
                                            </Button>
                                            {/* <Button
                                                variant="standart"
                                                disabled={true}
                                                sx={{
                                                  fontSize: "12px",
                                                  borderRadius: "22px",
                                                  backgroundColor:
                                                    paymentMethod === "card"
                                                      ? Colors.secondary
                                                      : Colors.white,
                                                  color:
                                                    paymentMethod === "card"
                                                      ? Colors.white
                                                      : Colors.primary,
                                                  ":hover": {
                                                    backgroundColor:
                                                      paymentMethod === "card"
                                                        ? Colors.secondary
                                                        : Colors.primary,
                                                    color: Colors.white,
                                                  },
                                                  transition:
                                                    "all .3s ease-in-out",
                                                  px: "20px",
                                                }}
                                                onClick={() =>
                                                  setPaymentMethod("card")
                                                }
                                              >
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    gap: "5px",
                                                  }}
                                                >
                                                  <CreditCard
                                                    sx={{ fontSize: "20px" }}
                                                  />
                                                  Card
                                                </Box>
                                              </Button> */}
                                          </FormControl>
                                        </Box>
                                        <FormHelperText
                                          sx={{
                                            display: errors?.paymentMethod
                                              ? "block"
                                              : "none",
                                          }}
                                          error={errors?.paymentMethod && true}
                                        >
                                          {errors?.paymentMethod?.message}
                                        </FormHelperText>
                                        <Typography
                                          variant="subtitle2"
                                          sx={{ mt: 2 }}
                                        >
                                          Payment will be made after the service
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            )}

                            {/* Stepper Buttons */}
                            <Box
                              sx={{
                                position: "relative",
                                mt: "10px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: activeStep === 4 ? "none" : "flex",
                                  flexDirection: "row",
                                  justifyContent: activeStep !== 1 ? "space-between" : "space-around",
                                  alignItems: "center",
                                  py: 1,
                                  boxShadow: {
                                    md: "none",
                                    sm: "0px -1px 5px 1px #c4c4c4",
                                    xs: "0px -1px 5px 1px #c4c4c4",
                                  },
                                  position: {
                                    md: "static",
                                    sm: "fixed",
                                    xs: "fixed",
                                  },
                                  bottom: { md: "0", sm: 0, xs: 0 },
                                  right: { md: "0", sm: 0, xs: 0 },
                                  width: "100%",
                                  backgroundColor: {
                                    md: "none",
                                    sm: Colors.smokeWhite,
                                    xs: Colors.smokeWhite,
                                  },
                                  zIndex: { sm: 999, xs: 999 },
                                }}
                              >
                                <Button
                                  disabled={activeStep === 0}
                                  onClick={handleBack}
                                  sx={{
                                    mr: 1,
                                    ":hover": { color: Colors.primary },
                                    border: `2px solid #1FC6C7`,
                                    backgroundColor: "#1FC6C7",
                                    color: "white",
                                    display: {
                                      md: "block",
                                      sm: "none",
                                      xs: "none",
                                    },
                                    p: "6px 24px",
                                  }}
                                >
                                  Back
                                </Button>
                                <Button
                                  disabled={selectedService?.pricing_type !== "Contact us" ? false : true}
                                  sx={{
                                    display: {
                                      md: "none",
                                      sm: "block",
                                      xs: "block",
                                    },
                                    color: Colors.primary,
                                    fontSize: "14px",
                                  }}
                                  onClick={() => setState(true)}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <b>Total</b>
                                    <Box
                                      component={"span"}
                                      sx={{ display: "flex" }}
                                    >
                                      {` AED ${grandTotal1.toFixed(2)}`}
                                      <KeyboardArrowUp />
                                    </Box>
                                  </Box>
                                </Button>

                                <Button
                                  type="submit"
                                  // onClick={handleNext}

                                  sx={{
                                    ":hover": { color: Colors.primary },
                                    border: `2px solid #1FC6C7`,
                                    backgroundColor: "#1FC6C7",
                                    color: "white",
                                    padding: "6px 24px",
                                  }}
                                  disabled={
                                    activeStep === steps.length - 1 &&
                                    SubmitDisabled &&
                                    !isChecked ||
                                    activeStep === 1 &&
                                    selectedDate.length < 1
                                  }
                                >
                                  {activeStep === steps.length - 1 ||
                                    (activeStep == 2 &&
                                      selectedService.pricing_type ==
                                      "Contact us")
                                    ? "Submit"
                                    : "Next"}
                                </Button>
                              </Box>
                            </Box>
                            {/* Stepper Buttons */}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4.5}
                            display={activeStep === 4 ? "none" : "block"}
                            sx={{
                              display: {
                                md: activeStep === 0 ? "none" : "block",
                                sm: "none",
                                xs: "none",
                              },
                            }}
                          >
                            <Box sx={{ position: "fixed", width: "31.28%" }}>
                              <Box
                                component={Paper}
                                sx={{
                                  borderRadius: 0,
                                  mb: 2,
                                  display:
                                    selectedService?.name === "Office Cleaning"
                                      ? "none"
                                      : "block",
                                }}
                              >
                                <Box sx={{ pt: "20px", px: "30px" }}>
                                  <Typography
                                    variant="h6"
                                    sx={{ fontWeight: 500, fontSize: "20px" }}
                                  >
                                    BOOKING SUMMARY
                                  </Typography>
                                </Box>
                                <Box sx={{ p: "10px 30px" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      p: "5px 0",
                                    }}
                                  >
                                    <Typography variant="subtitle2">Service Type </Typography>
                                    <Typography variant="subtitle2">{selectedService?.name}</Typography>
                                  </Box>
                                </Box>
                                <Box>
                                  <Accordion disableGutters square={true}>
                                    <AccordionSummary
                                      sx={{
                                        backgroundColor: Colors.secondary,
                                        color: Colors.white,
                                        p: "0px 30px",
                                        "& .MuiAccordionSummary-expandIconWrapper": {
                                          backgroundColor: Colors.white,
                                          borderRadius: "50%",
                                        },
                                      }}
                                      expandIcon={<ChevronRight sx={{ color: Colors.secondary }} />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography variant="subtitle2">Details</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      sx={{
                                        p: 0,
                                        minHeight: selectedService?.pricing_type == "hour" ? "100px" : "130px",
                                        maxHeight: "300px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      {arrayOfObjects?.map((obj, i) => (
                                        <Fragment>
                                          <Box key={i} sx={{ p: "16px 30px" }}>
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                p: "5px 0",
                                              }}
                                            >
                                              <Typography
                                                variant="subtitle2"
                                                sx={{ color: Colors.secondary, fontWeight: 600 }}
                                              >
                                                {obj.date.toLocaleDateString()}
                                              </Typography>
                                              <Typography variant="subtitle2">Arrival Time</Typography>
                                            </Box>
                                            <Box
                                              sx={{
                                                display: selectedService?.name == "Home Cleaning" ||
                                                  selectedService?.name == "Folding & Ironing Service"
                                                  ? "flex"
                                                  : "none",
                                                justifyContent: "space-between",
                                                p: "5px 0",
                                              }}
                                            >
                                              {obj.hours == 8 ? (
                                                <Fragment>
                                                  <Typography variant="subtitle2">
                                                    Morning
                                                  </Typography>
                                                  <Typography variant="subtitle2">
                                                    (8am-10am)
                                                  </Typography>
                                                </Fragment>
                                              ) : (
                                                <Fragment>
                                                  <Typography variant="subtitle2">
                                                    {obj.slot}
                                                  </Typography>
                                                  <Typography variant="subtitle2">
                                                    {obj.arrivalTime}
                                                  </Typography>
                                                </Fragment>
                                              )}
                                            </Box>
                                            <Box
                                              sx={{
                                                display: selectedService?.name == "AirBnB/Rental Home Cleaning"
                                                  ? "flex"
                                                  : "none",
                                                justifyContent: "space-between",
                                                p: "5px 0",
                                              }}
                                            >
                                              {obj.slot == "8am" || obj.slot == "9am" || obj.slot == "10am" ||
                                                obj.slot == "11am"
                                                ? (
                                                  <Fragment>
                                                    <Typography variant="subtitle2">
                                                      Morning
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                      {obj.slot}
                                                    </Typography>
                                                  </Fragment>
                                                )
                                                : obj.slot == "12pm" ? (
                                                  <Fragment>
                                                    <Typography variant="subtitle2">
                                                      Afternoon
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                      {obj.slot}
                                                    </Typography>
                                                  </Fragment>
                                                )
                                                  : <></>
                                              }
                                            </Box>
                                            <Box
                                              sx={{
                                                display: selectedService?.name == "Deep Cleaning"
                                                  ? "flex"
                                                  : "none",
                                                justifyContent: "space-between",
                                                p: "5px 0",
                                              }}
                                            >
                                              {obj.slot == "Morning" ?
                                                (
                                                  <Fragment>
                                                    <Typography variant="subtitle2">
                                                      {obj.slot}
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                      (8am-10am)
                                                    </Typography>
                                                  </Fragment>
                                                )
                                                : obj.slot == "Afternoon" ? (
                                                  <Fragment>
                                                    <Typography variant="subtitle2">
                                                      {obj.slot}
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                      (12pm-2pm)
                                                    </Typography>
                                                  </Fragment>
                                                )
                                                  : <></>
                                              }
                                            </Box>
                                            <Box
                                              sx={{
                                                display:
                                                  selectedService?.pricing_type == "hour"
                                                    ? "flex"
                                                    : "none",
                                                justifyContent: "space-between",
                                                p: "5px 0",
                                              }}
                                            >
                                              <Typography variant="subtitle2">
                                                Number of Maids
                                              </Typography>
                                              <Typography variant="subtitle2">
                                                {isNaN(obj.maids) ? "-" : obj.maids}
                                              </Typography>
                                            </Box>
                                            <Box
                                              sx={{
                                                display:
                                                  selectedService?.pricing_type == "hour"
                                                    ? "flex"
                                                    : "none",
                                                justifyContent: "space-between",
                                                p: "5px 0",
                                              }}
                                            >
                                              <Typography variant="subtitle2">
                                                Number of Hours
                                              </Typography>
                                              <Typography variant="subtitle2">
                                                {isNaN(obj.hours) ? "-" : obj.hours}
                                              </Typography>
                                            </Box>
                                            <Box
                                              sx={{
                                                display:
                                                  selectedService?.pricing_type == "hour"
                                                    ? "flex"
                                                    : "none",
                                                justifyContent: "space-between",
                                                p: "5px 0",
                                              }}
                                            >
                                              <Typography variant="subtitle2">
                                                Cleaning Material
                                              </Typography>
                                              <Typography variant="subtitle2">
                                                {obj.cleaning_material == true ? "Yes" : "No"}
                                              </Typography>
                                            </Box>
                                            <Box
                                              sx={{
                                                display:
                                                  selectedService?.name == "Deep Cleaning"
                                                    || selectedService?.name == "AirBnB/Rental Home Cleaning"
                                                    ? "flex"
                                                    : "none",
                                                justifyContent: "space-between",
                                                p: "5px 0",
                                              }}
                                            >
                                              <Typography variant="subtitle2">
                                                Number of Bedrooms
                                              </Typography>
                                              <Typography variant="subtitle2">{selectedPackage?.space}</Typography>
                                            </Box>
                                          </Box>
                                          <Divider />
                                        </Fragment>
                                      ))}
                                    </AccordionDetails>
                                  </Accordion>
                                  <Accordion
                                    disableGutters
                                    square={true}
                                    sx={{
                                      "&.MuiAccordion-root:before": {
                                        top: "-2px",
                                        height: "2px",
                                        backgroundColor: Colors.white,
                                        opacity: 1,
                                      },
                                    }}
                                  >
                                    <AccordionSummary
                                      sx={{
                                        backgroundColor: Colors.secondary,
                                        color: Colors.white,
                                        p: "0px 30px",
                                        "& .MuiAccordionSummary-expandIconWrapper": {
                                          backgroundColor: Colors.white,
                                          borderRadius: "50%",
                                        },
                                      }}
                                      expandIcon={<ChevronRight sx={{ color: Colors.secondary }} />}
                                      aria-controls="panel2a-content"
                                      id="panel2a-header"
                                    >
                                      <Typography variant="subtitle2">Address</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ p: "16px 30px" }}>
                                      <Typography variant="subtitle2">{address}</Typography>
                                    </AccordionDetails>
                                  </Accordion>
                                  <Accordion
                                    disableGutters
                                    square={true}
                                    expanded
                                    sx={{
                                      "&.MuiAccordion-root.Mui-expanded:before": {
                                        top: "-2px",
                                        height: "2px",
                                        backgroundColor: Colors.white,
                                        opacity: 1,
                                      },
                                    }}
                                  >
                                    <AccordionSummary
                                      sx={{
                                        backgroundColor: Colors.secondary,
                                        color: Colors.white,
                                        p: "0px 30px",
                                        "& .MuiAccordionSummary-expandIconWrapper": {
                                          backgroundColor: Colors.white,
                                          borderRadius: "50%",
                                        },
                                      }}
                                      aria-controls="panel3a-content"
                                      id="panel3a-header"
                                    >
                                      <Typography variant="subtitle2">Price Details</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ p: "16px 30px" }}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          p: "5px 0",
                                        }}
                                      >
                                        <Typography variant="subtitle2">Price </Typography>
                                        <Typography variant="subtitle2">AED {price1?.toFixed(2)}</Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          display:
                                            selectedService?.name === "Home Cleaning" ||
                                              selectedService?.name === "Folding & Ironing Service"
                                              ? "flex"
                                              : "none",
                                          justifyContent: "space-between",
                                          p: "5px 0",
                                        }}
                                      >
                                        <Typography variant="subtitle2">Material Price </Typography>
                                        <Typography variant="subtitle2">
                                          AED {materialPrice1?.toFixed(2)}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          p: "5px 0",
                                        }}
                                      >
                                        <Typography variant="subtitle2">Taxable </Typography>
                                        <Typography variant="subtitle2">
                                          AED {taxable1?.toFixed(2)}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          p: "5px 0",
                                        }}
                                      >
                                        <Typography variant="subtitle2">VAT 5% </Typography>
                                        <Typography variant="subtitle2">
                                          AED {vat1?.toFixed(2)}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          p: "5px 0",
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={{
                                            fontSize: "20px",
                                            color: Colors.secondary,
                                          }}
                                        >
                                          Total{" "}
                                        </Typography>
                                        <Typography
                                          variant="subtitle2"
                                          sx={{
                                            fontSize: "20px",
                                            color: Colors.secondary,
                                          }}
                                        >
                                          {`AED ${grandTotal1?.toFixed(2)}`}
                                        </Typography>
                                      </Box>
                                    </AccordionDetails>
                                  </Accordion>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Fragment>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Fragment>
            )}
          </Grid>
        </Box>
      </Container>
      <BookingSummaryDrawer
        open={state}
        onClose={() => setState(false)}
        selectedService={selectedService}
        selectedPackage={selectedPackage}
        arrayOfObjects={arrayOfObjects}
        address={address}
        setPrice1={setPrice1}
        setMaterialPrice1={setMaterialPrice1}
        setTaxable1={setTaxable1}
        setVat1={setVat1}
        setGrandTotal1={setGrandTotal1}
      />
    </Box>
  );
}

export default Booking;
