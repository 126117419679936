import Login from "../View/Auth/Login";
import Booking from "../View/Booking/Booking";
import POrderDetails from "../View/Orders/POrderDetails";

const PublicRoutes = [
  {
    path: "/",
    component: <Booking />,
  },
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/order-details/:id",
    component: <POrderDetails />,
  },
];

export default PublicRoutes;
