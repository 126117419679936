import { AuthRoutes } from "./Auth.routes";
import { post, get } from "../index";

const AuthService = {
  login: async (obj) => {
    let result = await post(AuthRoutes.login, obj);
    return result;
  },
  getOrders: async (page, limit, search, serviceId, fromDate, toDate) => {
    let result = await get(
      AuthRoutes.getOrders +
      `?page=${page}&limit=${limit}&search=${search ? search : ""
      }&service_id=${serviceId ? serviceId : ""}&from_date=${fromDate ? fromDate : ""
      }&to_date=${toDate ? toDate : ""}`
    );
    return result;
  },

  getOrderDetailById: async (id) => {
    let result = get(AuthRoutes.getOrderDetailsById + `?query_id=${id}`);
    return result;
  }
};

export default AuthService;
