import { FormControl, TextField, Typography } from "@mui/material";
import { Fragment } from "react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Colors } from "../../Assets/Styles/Colors";

function DatePicker({ label, value, size, views, openTo, error, disabled, disablePast, disableFuture, register, onChange, minDate, maxDate }) {

  return (
    <Fragment>
      <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DemoContainer components={['DesktopDatePicker']} sx={{ pt: 1 }}>
            <DesktopDatePicker
              label={label}
              openTo={openTo}
              views={views}
              disabled={disabled}
              disablePast={disablePast}
              disableFuture={disableFuture}
              value={value}
              minDate={minDate}
              maxDate={maxDate}
              sx={{ width: 1 }}
              onChange={onChange}
              slotProps={{ textField: { size: size } }}
              renderInput={(params) =>
                <TextField />
              }
            />
          </DemoContainer>
        </LocalizationProvider>
      </FormControl>
    </Fragment>
  );
}

export default DatePicker