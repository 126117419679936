import React, { Fragment } from "react";
import Header from "./Header";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Colors } from "../Assets/Styles/Colors";

export const Layout = () => {
  return (
    <Fragment>
      {/* ========== Header ========== */}
      <Header />

      <Box sx={{ display: "flex" }}>
        <Box sx={{ mt: "55px", flexGrow: 1, backgroundColor: Colors.smokeWhite }}>  {/* mx: 2,  */}
          <Outlet />
        </Box>
      </Box>
    </Fragment>
  );
};
