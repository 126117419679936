import React, { useState, useEffect } from "react";
import { Box, Grid, Container, Typography, Paper, Button, Accordion, AccordionSummary, AccordionDetails, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { Colors } from "../../Assets/Styles/Colors";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { LocationOn, Mail, NearMeRounded, Person, PhoneInTalk, Streetview, Villa } from "@mui/icons-material";
import useAuth from "../../Hooks/useAuth";
import moment from "moment";
import AuthService from "../../Api/Auth/AuthService";

function POrderDetails() {
  const tableHead = ["Date", "Slot", "Hours", "Maids", "Type", "Room", "Supplies"];
  const { id } = useParams();
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState({});
  console.log("🚀 ~ OrderDetails ~ orderDetails:", orderDetails)

  const openMap = () => {
    // Replace LATITUDE and LONGITUDE with your actual values
    const latitude = orderDetails?.contact?.latitude;
    const longitude = orderDetails?.contact?.longitude;

    // Create the map URL
    const mapUrl = `https://maps.google.com/maps?q=${latitude},${longitude}`;

    // Open the map in a new window or tab
    window.open(mapUrl, "_blank");
  };

  let servicePrice = orderDetails?.summary?.price;
  let materialPrice = orderDetails?.summary?.material_price;
  let totalMaterialPrice = 0;
  let totalPrice = 0
  for (let i = 0; i < orderDetails?.frequency?.length; i++) {
    if (orderDetails?.summary?.pricing_type == "hour") {
      totalPrice += servicePrice * orderDetails?.frequency[i]?.hours * orderDetails?.frequency[i]?.maids;
    } else {
      totalPrice += orderDetails?.summary?.package?.price;
    }
    if (orderDetails?.frequency[i]?.cleaning_material == true) {
      totalMaterialPrice += materialPrice * orderDetails?.frequency[i]?.maids;
    }
  }

  const getOrderDetailById = async (id) => {
    try {
      const { data, responseCode } = await AuthService.getOrderDetailById(id);
      if (responseCode == 200) {
        setOrderDetails(data.details);
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getOrderDetailById(id)
  }, []);

  return (

    <Box sx={{ mt: "40px", mb: "40px", }}>
      <Container>
        <Grid container spacing={3}>
          <Grid item md={6} sm={12} xs={12}>
            <Box
              sx={{
                minHeight: "100%",
                borderRadius: "8px",
                backgroundColor: Colors.white,
                boxShadow: "0px 3px 8px #91919140",
              }}
            >
              <Box
                sx={{
                  p: 4,
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Typography variant={"h5"} sx={{ fontWeight: 600 }}>Customer Details</Typography>
                <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <Person sx={{ fontSize: { md: "20px", sm: "16px", xs: "14px" }, color: Colors.secondary }} />
                    <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>Name:</Typography>
                  </Box>
                  <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>{orderDetails?.contact?.name}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <Mail sx={{ fontSize: { md: "20px", sm: "16px", xs: "14px" }, color: Colors.secondary }} />
                    <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>Email:</Typography>
                  </Box>
                  <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>{orderDetails?.contact?.email}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <PhoneInTalk sx={{ fontSize: { md: "20px", sm: "16px", xs: "14px" }, color: Colors.secondary }} />
                    <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>Phone Number:</Typography>
                  </Box>
                  <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>+971{orderDetails?.contact?.number}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <Streetview sx={{ fontSize: { md: "20px", sm: "16px", xs: "14px" }, color: Colors.secondary }} />
                    <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>Building/Street Name:</Typography>
                  </Box>
                  <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>{orderDetails?.contact?.building}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <Villa sx={{ fontSize: { md: "20px", sm: "16px", xs: "14px" }, color: Colors.secondary }} />
                    <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>Flat/Villa Number:</Typography>
                  </Box>
                  <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>{orderDetails?.contact?.flat}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", gap: "30px" }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <LocationOn sx={{ fontSize: { md: "20px", sm: "16px", xs: "14px" }, color: Colors.secondary }} />
                    <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>Address:</Typography>
                  </Box>
                  <Typography
                    sx={{ maxWidth: "fit-content", minWidth: "100px", fontSize: { md: "16px", sm: "14px", xs: "12px" } }}
                  >
                    {`${orderDetails?.contact?.address}, ${orderDetails?.contact?.city}`}
                  </Typography>
                </Box>
                <Box>
                  <Button onClick={openMap} sx={{ color: Colors.primary, gap: "20px" }}>
                    <Typography sx={{ fontWeight: 600 }}>
                      View Location
                    </Typography>
                    <Box
                      sx={{
                        backgroundColor: Colors.primary,
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        boxShadow: "-4px 4px 8px #6cbe1e7d"
                      }}
                    >
                      <NearMeRounded sx={{ fontSize: "20px", color: Colors.white }} />
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12} display={orderDetails?.summary?.name == "Office Cleaning" ? "none" : "block"}>
            <Box sx={{
              minHeight: "100%", borderRadius: "8px",
              backgroundColor: Colors.white,
              boxShadow: "0px 3px 8px #91919140",
            }}>
              <Box
                sx={{
                  p: 4,
                  display: "flex",
                  flexDirection: "column",
                  gap: "28px",
                }}
              >
                <Typography variant={"h5"} sx={{ fontWeight: 600 }}>Payment Details</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>Price:</Typography>
                  <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>AED {(totalPrice).toFixed(2)}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>Supplies Price:</Typography>
                  <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>AED {orderDetails?.summary?.pricing_type == "hour" ? (totalMaterialPrice).toFixed(2) : parseFloat(0).toFixed(2)}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>Taxable:</Typography>
                  <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>
                    AED {orderDetails?.payment?.sub_total?.toFixed(2)}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>VAT 5%:</Typography>
                  <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>
                    AED {parseFloat(orderDetails?.payment?.sub_total * 0.05)?.toFixed(2)}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>Payment Method:</Typography>
                  <Typography sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" } }}>
                    {orderDetails?.payment?.method === ""
                      ? "-"
                      : orderDetails?.payment?.method}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontSize: "20px", fontWeight: 600, color: Colors.secondary }}>Total:</Typography>
                  <Typography sx={{ fontSize: "20px", fontWeight: 600, color: Colors.secondary }}>
                    AED {orderDetails?.payment?.total?.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={orderDetails?.summary?.name == "Office Cleaning" ? 6 : 12} sm={12} xs={12}>
            <Box sx={{
              borderRadius: "8px",
              backgroundColor: Colors.white,
              boxShadow: "0px 3px 8px #91919140",
            }}>
              <Box sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}>
                <Typography variant={"h5"} sx={{ fontWeight: 600 }}>Summary Details</Typography>
                <Box sx={{
                  display: "flex",
                  gap: "10px"
                }}>
                  <Typography>Service Name:</Typography>
                  <Typography>{orderDetails?.summary?.name}</Typography>
                </Box>
              </Box>
              {/* Accordion */}
              <Box sx={{ display: orderDetails?.summary?.name !== "Office Cleaning" ? "block" : "none" }}>
                <Accordion disableGutters square={true} expanded sx={{ boxShadow: "none", }}>
                  <AccordionSummary
                    sx={{
                      backgroundColor: Colors.secondary,
                      color: Colors.white,
                      p: "0px 30px",
                      cursor: "default !important",
                      "& .MuiAccordionSummary-expandIconWrapper": {
                        backgroundColor: Colors.white,
                        borderRadius: "50%",
                      },

                    }}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="subtitle2">Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    <TableContainer>
                      <Table sx={{ minWidth: 350, overflow: "scroll" }}>
                        <TableHead>
                          <TableRow>
                            {tableHead.map((cell, i) => {
                              if (orderDetails?.summary?.pricing_type === "hour" &&
                                (cell === "Date" || cell === "Slot" || cell === "Hours" || cell === "Maids" || cell === "Supplies")) {
                                return (
                                  <TableCell key={i}>
                                    {cell}
                                  </TableCell>
                                );
                              }
                              if (orderDetails?.summary?.name === "AirBnB/Rental Home Cleaning" &&
                                (cell === "Date" || cell === "Slot" || cell === "Room")) {
                                return (
                                  <TableCell key={i}>
                                    {cell}
                                  </TableCell>
                                );
                              }
                              if (orderDetails?.summary?.name === "Deep Cleaning" &&
                                (cell === "Date" || cell === "Slot" || cell === "Type" || cell === "Room")) {
                                return (
                                  <TableCell key={i}>
                                    {cell}
                                  </TableCell>
                                );
                              }
                              return null;
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orderDetails?.frequency?.map((obj, i) => (
                            <TableRow key={i}>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>
                                {moment(obj.date).format("DD-MM-yyyy")}
                              </TableCell>
                              <TableCell>
                                {obj.hours == 8 ? "Morning(8am-10am)" : obj.slot}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center", display: orderDetails?.summary?.pricing_type == "hour" ? "table-cell" : "none" }}>
                                <Box
                                  sx={{
                                    width: "35px",
                                    height: "35px",
                                    borderRadius: "50%",
                                    backgroundColor: Colors.secondary,
                                    color: Colors.white,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {obj.hours}
                                </Box>
                              </TableCell>
                              <TableCell sx={{ textAlign: "center", display: orderDetails?.summary?.pricing_type == "hour" ? "table-cell" : "none" }}>
                                <Box
                                  sx={{
                                    width: "35px",
                                    height: "35px",
                                    borderRadius: "50%",
                                    backgroundColor: Colors.secondary,
                                    color: Colors.white,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {obj.maids}
                                </Box>
                              </TableCell>
                              <TableCell
                                sx={{ display: orderDetails?.summary?.name == "Deep Cleaning" ? "table-cell" : "none" }}
                              >
                                {orderDetails?.summary?.house_type}
                              </TableCell>
                              <TableCell sx={{ display: orderDetails?.summary?.pricing_type == "starting_from" ? "table-cell" : "none" }}>
                                <Box
                                  sx={{
                                    width: "120px",
                                    height: "35px",
                                    borderRadius: "24px",
                                    backgroundColor: Colors.secondary,
                                    color: Colors.white,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {orderDetails?.summary?.package?.option}
                                </Box>
                              </TableCell>
                              <TableCell
                                sx={{ display: orderDetails?.summary?.pricing_type == "hour" ? "table-cell" : "none" }}
                              >
                                {obj.cleaning_material == true ? "Yes" : "No"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box sx={{ display: orderDetails?.summary?.name !== "Office Cleaning" ? "flex" : "none", gap: "10px", p: 2 }}>
                <Typography sx={{ fontWeight: 600 }}>Notes:</Typography>
                <Typography>
                  {orderDetails?.summary?.notes === "" ? "-" : orderDetails?.summary?.notes}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default POrderDetails;
