import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { Colors } from "../../Assets/Styles/Colors";

function ServiceCard({ image, title, desc, onClick, selected }) {
  return (
    <Card
      sx={{
        p: "6px",
        maxWidth: 230,
        borderRadius: 1.5,
        // height: "100%",
        boxShadow: selected
          ? `0px 0px 3px 1px ${Colors.secondary}`
          : "0px 0px 8px #91919140",
        transition: "all .3s ease-in-out",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer",
        gap: "5px",
      }}
      onClick={onClick}
    >
      <CardMedia
        component={"img"}
        sx={{
          height: { md: 120, sm: 100, xs: 70 },
          objectFit: "cover",
          borderRadius: "6px",
        }}
        src={image}
        title={"service"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "5px",
        }}
      >
        <Typography
          component={"div"}
          variant="subtitle2"
          sx={{
            fontWeight: 600,
            fontSize: { md: "12px", sm: "10px", xs: "8px" },
          }}
        >
          {title}
        </Typography>
        <Typography
          component={"div"}
          variant="caption"
          color="text.secondary"
          sx={{
            fontSize: { md: "10px", sm: "9px", xs: "7px" },
          }}
        >
          {desc}
        </Typography>
        <Button
          variant="contained"
          size="small"
          sx={{
            boxShadow: "none",
            background: selected ? Colors.secondary : Colors.primary,
            color: Colors.white,
            justifyContent: "center",
            borderRadius: 2,
            mb: 0.5,
          }}
          onClick={onClick}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: { md: "10px", sm: "9px", xs: "8px" },
              fontWeight: 400,
              m: 0,
            }}
          >
            {selected ? "Selected" : "Select"}
          </Typography>
        </Button>
      </Box>
    </Card>
  );
}

export default ServiceCard;
