import { Grid, Box, Button, AppBar, Toolbar, CardMedia } from "@mui/material";
import { Colors } from "../Assets/Styles/Colors";
import { useNavigate } from "react-router-dom";
import { Images } from "../Assets/Images/Images";
import { useAuth } from "../Context/UseContext";

const Header = () => {
  const { userLogout } = useAuth();
  const navigate = useNavigate();

  const logout = async () => {
    userLogout();
    navigate("/login");
  };

  return (
    <AppBar sx={{ mb: 20, bgcolor: "white", boxShadow: "0px 0px 5px 1px #c4c4c4" }}>
      <Toolbar>
        <Grid container py={2} px={3} justifyContent={"space-between"}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                float: "right",
                gap: "10px",
                width: "100%",
              }}
            >
              <Box sx={{ backgroundColor: Colors.white }}>
                <CardMedia
                  component={"img"}
                  src={Images.logo}
                  width={"100px"}
                  height={"50px"}
                  sx={{ objectFit: "contain" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: Colors.white,
                  "&:hover": {
                    backgroundColor: Colors.primary,
                    cursor: "pointer",
                  },
                  transition: "all .3s ease-in-out",
                }}
              >
                <Button
                  sx={{
                    ":hover": { color: Colors.white,borderRadius:"5px" },
                    color: Colors.primary,
                    border:`1px solid ${Colors.primary}}`
                  }}
                  onClick={logout}
                >
                  LOG OUT
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
