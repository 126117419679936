import React, { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Add,
  Close,
  Favorite,
  Home,
  Work,
  LocalLibrary,
} from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Typography,
  Button,
  TextField,
  Autocomplete,
  InputAdornment,
  CircularProgress
} from "@mui/material";
import { Colors } from "../Assets/Styles/Colors";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import InputField from "./InputField/InputField";
// import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import usePlacesAutocomplete from "use-places-autocomplete";

import Geocode from "react-geocode";

const googleMapKey = "AIzaSyCsT-b8-J4wnqKYUBFROMPQr_IEYdjNiSg";

const PlacesAutocomplete = ({ address, geoAddress, setAddress, readOnly, setReadOnly }) => {
  console.log("🚀 ~ PlacesAutocomplete ~ address:", address)
  const {
    setValue,
    suggestions: { data },
  } = usePlacesAutocomplete({
    requestOptions: {
      region: "ae",
      componentRestrictions: { country: "ae" },
    },
  });
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (inputValue.length > 0) {
      setOpen(true);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    setValue(newInputValue);
    const hasDubai = data.some((places) => places.terms.some((term) => term.value == "Dubai"));
    if (hasDubai) {
      geoAddress(newInputValue);
      setAddress(newInputValue);
    }
    if (newInputValue.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleInput = () => {
    setInputValue("");
    setReadOnly(false);
  }

  // useEffect(() => {
  //   setInputValue("");
  // }, [reset])

  return (
    <Autocomplete
      sx={{ my: 2 }}
      size="small"
      fullWidth
      id="combo-box-demo"
      onInputChange={handleInputChange}
      value={inputValue}
      open={open}
      readOnly={readOnly}
      onOpen={handleOpen}
      onClose={() => setOpen(false)}
      options={data.map((option) => option.description)}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label="Please select your PIN location to proceed"
          onClick={handleInput}
        />
      )}
      filterOptions={(options) => {
        const filteredOptions = options.filter(
          (option) => option.toLowerCase().includes("dubai")
        );
        return filteredOptions;
      }}
    />
  );
};

function Map({ newAddress, defaultData, setUpdatedMarker, setValue }) {

  // *For Map Positions
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });
  const [readOnly, setReadOnly] = useState(false);

  // *For Address
  const [address, setAddress] = useState("");

  const { isLoaded, loadError, google } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapKey,
    libraries: ["places"],
  });

  Geocode?.setApiKey(googleMapKey);
  Geocode?.setRegion("ae");

  const containerStyle = {
    width: "100%",
    height: "200px",
    borderRadius: "10px",
    boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
  };

  const options = {
    disableDefaultUI: true,
    zoomControl: false,
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ],
  };

  const handleMapLoad = (map) => {
    map.addListener("click", (e) => {
      Geocode?.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
        (response) => {
          setMarkerPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          setAddress(response.results[0].formatted_address);
        },
        (error) => {
          console.error(error);
        }
      );
    });
  };

  const handleMarkerLoad = (marker) => {
    marker.addListener("dragend", (e) => {
      Geocode?.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
        (response) => {
          setMarkerPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          setAddress(response.results[0].formatted_address);
        },
        (error) => {
          console.error(error);
        }
      );
    });
  };

  const geoAddress = (address) => {
    if (address === null || !address.toLowerCase().includes("dubai")) {
      return;
    } else if (address) {
      setValue("address", address)
      setReadOnly(true);
      Geocode?.fromAddress(address)
        .then((response) => {
          setCenter({
            lat: response.results[0]?.geometry?.location?.lat,
            lng: response.results[0]?.geometry?.location?.lng,
          });
          setMarkerPosition({
            lat: response.results[0]?.geometry?.location?.lat,
            lng: response.results[0]?.geometry?.location?.lng,
          });
          setUpdatedMarker({
            lat: response.results[0]?.geometry?.location?.lat,
            lng: response.results[0]?.geometry?.location?.lng,
          });
        })
        .catch((error) => {
          console.error("Error fetching Geocode data:", error);
        });
    }
  };

  useEffect(() => {
    if (defaultData) {
      setCenter({ lat: defaultData?.latitude, lng: defaultData?.longitude });
      setMarkerPosition({
        lat: defaultData?.latitude,
        lng: defaultData?.longitude,
      });
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setCenter({ lat: latitude, lng: longitude });
        setMarkerPosition({ lat: latitude, lng: longitude });
      });
    }
  }, []);

  useEffect(() => {
    if (address) {
      let obj = {
        address: address,
        latitude: center?.lat,
        longitude: center?.lng,
      };
      newAddress(obj);
    }
  }, [address]);

  if (!isLoaded) return (
    <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  );

  return (
    <Fragment>
      <PlacesAutocomplete
        setAddress={setAddress}
        address={address}
        geoAddress={geoAddress}
        readOnly={readOnly}
        setReadOnly={setReadOnly}
      />
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        options={options}
      // onLoad={handleMapLoad}
      >
        <MarkerF
          position={markerPosition}
          draggable={false}
          onLoad={handleMarkerLoad}
        />
      </GoogleMap>
    </Fragment>
  );
}

function AddressForm({ open, onClose, loading, defaultData, save }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();

  // *For Address Detail
  const [addressDetail, setAddressDetail] = useState();
  const [updatedMarker, setUpdatedmarker] = useState({ lat: 0, lng: 0 });
  const [city, setCity] = useState("");

  // *For Submit Form
  const submitForm = (formData) => {
    try {
      let obj = {
        ...addressDetail,
        address: formData.address,
        buildingStreet: formData.buildingStreet,
        flatVilla: formData.flatVilla,
        city: city,
        center: {
          latitude: updatedMarker.lat,
          longitude: updatedMarker.lng,
        },
      };
      save(obj);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          height: "auto",
          borderRadius: 2,
          py: { xs: 2, md: 3 },
          px: { xs: 2, md: 3 },
        },
      }}
    >
      <IconButton
        color="primary"
        onClick={() => {
          onClose();
          reset();
        }}
        sx={{ position: "absolute", right: 13, top: 13 }}
      >
        <Close />
      </IconButton>
      <DialogTitle
        sx={{ textAlign: "center", fontSize: "18px", fontWeight: 700 }}
      >
        {"Address"}
      </DialogTitle>
      <Box component="form" onSubmit={handleSubmit(submitForm)}>
        <Grid container spacing={1} alignItems={"flex-start"}>
          <Grid item xs={12} sm={12}>
            <Map
              newAddress={(data) => {
                setAddressDetail(data);
              }}
              defaultData={defaultData}
              setUpdatedMarker={setUpdatedmarker}
              setCity={setCity}
              setValue={setValue}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputLabel>{"Flat/Villa Number"}</InputLabel>
            <InputField
              fullWidth
              size={"small"}
              placeholder={"Flat/Villa Number"}
              error={errors?.flatVilla ? true : false}
              helperText={errors?.flatVilla?.message}
              register={register("flatVilla", {
                required: "Flat/Villa Number",
              })}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputLabel>{"Building/Street Name"}</InputLabel>
            <InputField
              fullWidth
              size={"small"}
              placeholder={"Building/Street Name"}
              error={errors?.buildingStreet ? true : false}
              helperText={errors?.buildingStreet?.message}
              register={register("buildingStreet", {
                required: "Building/Street Name",
              })}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputLabel>{"Area"}</InputLabel>
            <InputField
              fullWidth
              size={"small"}
              disabled={true}
              placeholder={"Area"}
              error={errors?.address?.message}
              helperText={errors?.address?.message}
              readOnly={true}
              register={register("address", {
                required: "Location",
              })}
            />
          </Grid>

          <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                title="cancel"
                onClick={() => {
                  onClose();
                }}
              >
                CLOSE
              </Button>
              <Box sx={{ mx: 0.5 }} />
              <Button type={"submit"}>SUBMIT</Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default AddressForm;
